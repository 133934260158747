import { TFunction } from "i18next";

import { Incoterms } from "@sellernote/_shared/src/types/common/common";
import {
  BidProjectStatus,
  BidStatus,
} from "@sellernote/_shared/src/types/forwarding/bid";
import {
  CouponKind,
  CouponStatus,
  CouponType,
} from "@sellernote/_shared/src/types/forwarding/coupon";

export function toCouponStatusString({
  status,
  t,
}: {
  status: CouponStatus;
  t: TFunction<"coupon">;
}) {
  switch (status) {
    case "used": {
      return t("coupon:쿠폰_상태_사용완료");
    }
    case "applied": {
      return t("coupon:쿠폰_상태_적용완료");
    }
    case "expired": {
      return t("coupon:쿠폰_상태_기한만료");
    }
    default: {
      return "";
    }
  }
}

/**
 * 쿠폰을 적용/취소할 수 있는지 확인
 */
export function canUpdateCouponToBid(
  status: BidStatus,
  incoterms: Incoterms,
  projectStatus?: BidProjectStatus,
  couponStatus?: CouponStatus
): boolean {
  // 쿠폰 정책 업데이트 - incoterms C로 시작되면 쿠폰버튼 노출 X
  const invalidIncotermsList: Incoterms[] = ["CFR", "CPT", "CIF"];
  if (invalidIncotermsList.includes(incoterms)) return false;

  if (couponStatus === "used") {
    return false;
  }

  switch (status) {
    case "waiting":
    case "waitingForExporterInfo": {
      return true;
    }

    case "inProgress": {
      const validProjectStatusList: BidProjectStatus[] = [
        "beforeContactPartner",
        "contactingPartner",
        "scheduling",
        "moving",
      ];

      if (projectStatus && validProjectStatusList.includes(projectStatus)) {
        return true;
      } else {
        return false;
      }
    }

    default: {
      return false;
    }
  }
}

export function getCouponTypeString(couponType: CouponType) {
  return couponType ? couponType.toUpperCase() : "";
}

export function convertCouponKindToKorean({
  couponKind,
  t,
}: {
  couponKind: CouponKind;
  t: TFunction<"coupon">;
}) {
  switch (couponKind) {
    case "oneTime":
      return t("coupon:쿠폰_타입_일회용");
    case "fixed":
      return t("coupon:쿠폰_타입_일반");
    case "programatic":
      return t("coupon:쿠폰_타입_자동발급");
    default:
      return "-";
  }
}
