import { useMemo } from "react";

import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "../../services/query";
import { toFormattedDate } from "../../utils/common/date";
import { getSupplyValueInUnit } from "../../utils/forwarding/bid";
import {
  changeBusinessManagementExcelDataServiceTypeToNameKr,
  changeBusinessManagementExcelStatusToNameKr,
  changeProceedReasonToNameKr,
} from "../../utils/forwarding/businessManagement";

import { QueryResponseHandlerSuccessModalInfo } from "../../components/QueryResponseHandler";

import {
  CHANGE_BID_STATUS_TO_ESTIMATE_REQ,
  CHANGE_BID_STATUS_TO_ESTIMATE_REQ_PATH_PARAMS,
  CHANGE_BID_STATUS_TO_ESTIMATE_RES,
  GET_BUSINESS_MANAGEMENT_COMMENT_REQ,
  GET_BUSINESS_MANAGEMENT_COMMENT_RES,
  GET_BUSINESS_MANAGEMENT_DB_LIST_REQ,
  GET_BUSINESS_MANAGEMENT_DB_LIST_RES,
  GET_BUSINESS_MANAGEMENT_EXCEL_LIST_REQ,
  GET_BUSINESS_MANAGEMENT_EXCEL_LIST_RES,
  GET_BUSINESS_MANAGEMENT_INFO_RES,
  GET_BUSINESS_MANAGEMENT_LIST_REQ,
  GET_BUSINESS_MANAGEMENT_LIST_RES,
  GET_BUSINESS_MANAGEMENT_SUMMARY_RES,
  GET_OPERATION_EXCEL_LIST_REQ,
  GET_OPERATION_EXCEL_LIST_RES,
  GET_OPERATION_LIST_REQ,
  GET_OPERATION_LIST_RES,
  GET_PROFIT_EXCEL_LIST_REQ,
  GET_PROFIT_EXCEL_LIST_RES,
  GET_PROFIT_LIST_REQ,
  GET_PROFIT_LIST_RES,
  GET_REMARKETING_HISTORY_REQ,
  GET_REMARKETING_HISTORY_RES,
  NO_PROCEED_REASON_UPDATE_REQ,
  NO_PROCEED_REASON_UPDATE_RES,
  REMARKETING_DATE_UPDATE_REQ,
  REMARKETING_DATE_UPDATE_RES,
  REMARKETING_MAIL_REQUEST_REQ,
  REMARKETING_MAIL_REQUEST_REQ_PATH_PARAMS,
  REMARKETING_MAIL_REQUEST_RES,
} from "../../api-interfaces/shipda-api/businessManagement";

export const BUSINESS_MANAGEMENT_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_BID_QUERY" }] as const,

  getBusinessManagementList: (params: GET_BUSINESS_MANAGEMENT_LIST_REQ) =>
    [
      {
        ...BUSINESS_MANAGEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getBusinessManagementList",
      },
    ] as const,

  getBusinessManagementSummary: () =>
    [
      {
        ...BUSINESS_MANAGEMENT_QUERY_KEY_GEN.all()[0],
        entity: "getBusinessManagementSummary",
      },
    ] as const,

  getBusinessManagementInfo: (id: number) =>
    [
      {
        ...BUSINESS_MANAGEMENT_QUERY_KEY_GEN.all()[0],
        id,
        entity: "getBusinessManagementInfo",
      },
    ] as const,

  getBusinessManagementExcelList: (
    params: GET_BUSINESS_MANAGEMENT_EXCEL_LIST_REQ
  ) =>
    [
      {
        ...BUSINESS_MANAGEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getBusinessManagementExcelList",
      },
    ] as const,

  getBusinessManagementDbList: (params: GET_BUSINESS_MANAGEMENT_DB_LIST_REQ) =>
    [
      {
        ...BUSINESS_MANAGEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getBusinessManagementDbList",
      },
    ] as const,

  getProfitList: (params: GET_PROFIT_LIST_REQ) =>
    [
      {
        ...BUSINESS_MANAGEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getProfitList",
      },
    ] as const,

  getProfitExcelList: (params: GET_PROFIT_EXCEL_LIST_REQ) =>
    [
      {
        ...BUSINESS_MANAGEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getProfitExcelList",
      },
    ] as const,

  getOperationList: (params: GET_OPERATION_LIST_REQ) =>
    [
      {
        ...BUSINESS_MANAGEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getOperationList",
      },
    ] as const,

  getOperationExcelList: (params: GET_OPERATION_EXCEL_LIST_REQ) =>
    [
      {
        ...BUSINESS_MANAGEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getOperationExcelList",
      },
    ] as const,

  getRemarketingHistory: (params: GET_REMARKETING_HISTORY_REQ) =>
    [
      {
        ...BUSINESS_MANAGEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getReMarketingHistory",
      },
    ] as const,

  getBusinessManagementCommentList: (
    params: GET_BUSINESS_MANAGEMENT_COMMENT_REQ
  ) =>
    [
      {
        ...BUSINESS_MANAGEMENT_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getBusinessManagementCommentList",
      },
    ] as const,
};

function useGetBusinessManagementList(
  params: GET_BUSINESS_MANAGEMENT_LIST_REQ,
  enabled: boolean
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getBusinessManagementList
    >,
    GET_BUSINESS_MANAGEMENT_LIST_RES
  >({
    queryKey:
      BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getBusinessManagementList(params),
    enabled,
    keepPreviousData: true,
    requestOptions: {
      method: "get",
      path: "/businessManagement",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  return { ...queryResult };
}

function useGetBusinessManagementInfo({ id }: { id: number }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getBusinessManagementInfo
    >,
    GET_BUSINESS_MANAGEMENT_INFO_RES
  >({
    queryKey: BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getBusinessManagementInfo(id),
    keepPreviousData: true,
    requestOptions: {
      method: "get",
      path: `/businessManagement/salesInfo/${id}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useGetBusinessManagementExcelList(
  params: GET_BUSINESS_MANAGEMENT_EXCEL_LIST_REQ,
  enabled: boolean
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getBusinessManagementExcelList
    >,
    GET_BUSINESS_MANAGEMENT_EXCEL_LIST_RES
  >({
    queryKey:
      BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getBusinessManagementExcelList(params),
    enabled,
    requestOptions: {
      method: "get",
      path: `/businessManagement/excel`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  const newExcelList = useMemo(() => {
    if (!queryResult.data) {
      return undefined;
    }
    let calls = 0;
    return queryResult.data.list.map((log, index, list) => {
      // bid 항목이 변경되면 콜수 다시 카운트
      if (index !== 0 && list[index - 1].bidId !== log.bidId) {
        calls = 1;
      } else {
        calls += 1;
      }

      // 전체 진행상태
      const bidStatus = log.status;

      // 세부 상태
      const bidProjectStatus = log.projectStatus;

      let inProgressFlag = false;
      // 진행 중
      if (bidStatus === "inProgress") {
        inProgressFlag = true;
      }
      // 수입 완료됐거나 정산 중인 상태
      if (
        bidStatus === "finished" &&
        bidProjectStatus !== "canceled" &&
        bidProjectStatus !== "failed" &&
        bidProjectStatus !== "expired"
      ) {
        inProgressFlag = true;
      }

      let isExporterPartner = false;

      if (log.exporterInfo && log.status !== "inProgress") {
        isExporterPartner = true;
      }

      if (
        log.serviceType === "consolidation" &&
        !log.itemId &&
        log.status !== "inProgress"
      ) {
        isExporterPartner = true;
      }

      const startPortName =
        log.startPortName ||
        log.startPortNameEN ||
        log.startViaPortName ||
        log.startViaPortNameEN;

      const endPortName =
        log.endPortName ||
        log.endPortNameEN ||
        log.endViaPortName ||
        log.endViaPortNameEN;

      // 견적 제출 관련 정보
      let autoEstimateFlag = "O";
      let quotationCreatedAt = "-";

      if (log.quotationCreatedAt) {
        // 유저에게 제공된 견적이 있는데 수동 견적인 경우
        autoEstimateFlag = "X";
        quotationCreatedAt = toFormattedDate(
          log.quotationCreatedAt,
          "YYYY-MM-DD"
        );
      }
      return {
        managerName: log.managerName,
        bidId: log.bidId,
        createdAt: toFormattedDate(log.bidCreatedAt, "YYYY-MM-DD"),
        companyName: log.userCompany,
        serviceType: changeBusinessManagementExcelDataServiceTypeToNameKr(
          log.serviceType,
          log.isExpress
        ),
        incoterms: log.incoterms,
        freightType: log.freightType,
        startPort: startPortName,
        endPort: endPortName,
        calls,
        productsInfo: getSupplyValueInUnit(log.freightType, log.supply),
        noProceedReason: changeProceedReasonToNameKr(log.logDetails),
        remarketingDate: toFormattedDate(log.remarketingDate, "YYYY-MM-DD"),
        status: changeBusinessManagementExcelStatusToNameKr(
          bidStatus,
          bidProjectStatus
        ),
        projectStatus: bidProjectStatus,
        inProgressFlag: inProgressFlag ? "O" : "X",
        contactedAt: toFormattedDate(log.contactedAt, "YYYY-MM-DD"),
        autoEstimateFlag,
        quotationCreatedAt,
        isExporterPartner: isExporterPartner ? "O" : "X",
        initialProgressDate: toFormattedDate(
          log.initialProgressDate,
          "YYYY-MM-DD"
        ),
      };
    });
  }, [queryResult]);

  return { ...queryResult, newExcelList };
}

function useGetBusinessManagementDbList(
  params: GET_BUSINESS_MANAGEMENT_DB_LIST_REQ,
  enabled: boolean
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getBusinessManagementDbList
    >,
    GET_BUSINESS_MANAGEMENT_DB_LIST_RES
  >({
    queryKey:
      BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getBusinessManagementDbList(params),
    requestOptions: {
      method: "get",
      path: `/businessManagement/DBManagement`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    enabled,
  });

  return { ...queryResult };
}

function useUpdateNoProceedReason(options?: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo;
}) {
  const mutation = useAppMutation<
    NO_PROCEED_REASON_UPDATE_REQ,
    NO_PROCEED_REASON_UPDATE_RES
  >({
    requestOptions: {
      method: "put",
      path: `/businessManagement`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo: options?.successModalInfo,
  });

  return { ...mutation };
}

function useUpdateRemarketingDate(options?: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo;
}) {
  const mutation = useAppMutation<
    REMARKETING_DATE_UPDATE_REQ,
    REMARKETING_DATE_UPDATE_RES
  >({
    requestOptions: {
      method: "put",
      path: `/businessManagement`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo: options?.successModalInfo,
  });

  return { ...mutation };
}

function useRequestRemarketingMail(options?: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo;
}) {
  const mutation = useAppMutation<
    REMARKETING_MAIL_REQUEST_REQ,
    REMARKETING_MAIL_REQUEST_RES,
    REMARKETING_MAIL_REQUEST_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "get",
      path: ({ bidId }) => `/businessManagement/remarketing/?bidId=${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo: options?.successModalInfo,
  });

  return { ...mutation };
}

function useGetBusinessManagementSummary() {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getBusinessManagementSummary
    >,
    GET_BUSINESS_MANAGEMENT_SUMMARY_RES
  >({
    queryKey: BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getBusinessManagementSummary(),
    requestOptions: {
      method: "get",
      path: `/businessManagement/summary`,
      apiType: "ShipdaAdminDefaultNew",
    },

    keepPreviousData: true,
  });

  return { ...queryResult };
}

function useGetProfitList(params: GET_PROFIT_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getProfitList>,
    GET_PROFIT_LIST_RES
  >({
    queryKey: BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getProfitList(params),
    requestOptions: {
      method: "get",
      path: `/businessManagement/profit`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  return { ...queryResult };
}

function useGetProfitExcelList({
  enabled,
  ...params
}: GET_PROFIT_EXCEL_LIST_REQ & { enabled?: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getProfitExcelList>,
    GET_PROFIT_EXCEL_LIST_RES
  >({
    queryKey: BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getProfitExcelList(params),
    requestOptions: {
      method: "get",
      path: `/businessManagement/profit`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useGetOperationList({
  enabled,
  ...params
}: GET_OPERATION_LIST_REQ & { enabled?: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getOperationList>,
    GET_OPERATION_LIST_RES
  >({
    queryKey: BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getOperationList(params),
    requestOptions: {
      method: "get",
      path: `/businessManagement/operation`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useGetOperationExcelList({
  enabled,
  ...params
}: GET_OPERATION_EXCEL_LIST_REQ & { enabled?: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getOperationExcelList>,
    GET_OPERATION_EXCEL_LIST_RES
  >({
    queryKey: BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getOperationExcelList(params),
    requestOptions: {
      method: "get",
      path: `/businessManagement/operation`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useGetRemarketingHistory(params: GET_REMARKETING_HISTORY_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getRemarketingHistory>,
    GET_REMARKETING_HISTORY_RES
  >({
    queryKey: BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getRemarketingHistory(params),
    requestOptions: {
      method: "get",
      path: "/businessManagement/remarketing/history",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  return { ...queryResult };
}

function useGetBusinessManagementCommentList(
  params: GET_REMARKETING_HISTORY_REQ
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getBusinessManagementCommentList
    >,
    GET_BUSINESS_MANAGEMENT_COMMENT_RES
  >({
    queryKey:
      BUSINESS_MANAGEMENT_QUERY_KEY_GEN.getBusinessManagementCommentList(
        params
      ),
    requestOptions: {
      method: "get",
      path: "/management/comment",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  return { ...queryResult };
}

function useChangeBidStatusToEstimate() {
  const mutation = useAppMutation<
    CHANGE_BID_STATUS_TO_ESTIMATE_REQ,
    CHANGE_BID_STATUS_TO_ESTIMATE_RES,
    CHANGE_BID_STATUS_TO_ESTIMATE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ bidId }) => `/bid/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

const BUSINESS_MANAGEMENT_QUERY = {
  useGetBusinessManagementInfo,
  useGetBusinessManagementList,
  useUpdateNoProceedReason,
  useUpdateRemarketingDate,
  useRequestRemarketingMail,
  useGetBusinessManagementExcelList,
  useGetBusinessManagementDbList,
  useGetBusinessManagementSummary,
  useGetProfitList,
  useGetProfitExcelList,
  useGetOperationList,
  useGetOperationExcelList,
  useGetRemarketingHistory,
  useGetBusinessManagementCommentList,
  useChangeBidStatusToEstimate,
};
export default BUSINESS_MANAGEMENT_QUERY;
