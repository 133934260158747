import { FileTypes } from "../../types/common/upload";

export function mergeFileInfoToUploadUrls(
  urls: { id: number; url: string }[],
  files: FileTypes[]
) {
  if (!files) return;
  if (!urls) return;

  const fileOptions = files.map(({ fileInfo, id }) => {
    const option = {
      id,
      method: "PUT",
      body: fileInfo,
    };

    return { id, option };
  });

  return urls.map((url, i) => {
    return Object.assign({}, url, fileOptions[i]);
  });
}

export function convertBytesToMegabytes(bytes: number) {
  return bytes / (1024 * 1024);
}

export type OptionType = { id: number; method: string; body: File };
export type RequestData = { id: number; url: string; option: OptionType };

type FileSizeUnit = "B" | "KB" | "MB" | "GB" | "TB";

/**
 * 파일 사이즈를 변환해주는 함수
 * @param param0
 * @returns 변환된 파일 값
 */
export function convertFileSize({
  size,
  fromUnit,
  toUnit,
}: {
  size: number;
  fromUnit: FileSizeUnit;
  toUnit: FileSizeUnit;
}) {
  const units = {
    B: 1,
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
    TB: 1024 * 1024 * 1024 * 1024,
  };

  const bytes = size * units[fromUnit];

  const convertedSize = bytes / units[toUnit];

  return convertedSize;
}
