import dayjs from "dayjs";

import "dayjs/locale/ko";
import "dayjs/locale/en-sg";

import { initDayJS, TimeZone } from "../../services/dayjs";

import { APP_CURRENT_LANGUAGE, APP_REGION } from "../../constants";
import {
  LUNAR_PUBLIC_HOLIDAY_LIST,
  PUBLIC_HOLIDAY_LIST,
  SUBSTITUTE_HOLIDAY_LIST,
} from "./constants";

initDayJS();

/**
 * @param {*} durationAsSecond 초로 환산된 duration
 */
function getExpiredAt(durationAsSecond: number) {
  if (!durationAsSecond) {
    return;
  }

  const duration = Number(durationAsSecond);

  if (isNaN(duration)) {
    return;
  }

  const expiredAt = dayjs().add(duration, "second").toDate();

  return expiredAt;
}

/**
 * 시간을 특정 포맷으로 변경해준다.
 * @param value 변환할 원본 시간 데이터
 * @param format 변환하고자 하는 데이터 포맷 (입력하지 않으면 KR은 "YYYY-MM-DD hh:mm:ss a", SG는 "DD/MM/YY hh:mm:ss a" 로 포맷팅)
 * AM/PM 표기 제외하고 24시간제로 변환할때는 HH로 시간을 대문자 사용.
 */
function toFormattedDate(
  value?: string | Date | number | null,
  format = APP_CURRENT_LANGUAGE === "ko"
    ? "YYYY-MM-DD hh:mm:ss a"
    : "DD/MM/YY hh:mm:ss a",
  /** timezone이 아니라 utc로 받고싶을때 */
  asUTC?: boolean,
  /**
   * dayjs.tz의 (APP_REGION에 따라 정해진)TimeZone을 무시하고 dayjs 인스턴스의 default TimeZone(host인 브라우저의 TimeZone과 동일) 사용하고 싶을때 사용.
   * - 가끔 dayjs.tz로는 dayjs 전역의 default TimeZone을 바꿀 수 없어서 충돌이 나는 경우가 있는데, 이 경우 충돌을 피하기 위해 사용할 수 있음
   * - (근본적으로는 TimeZone관련 컨벤션을 정리해서 해결해야함)
   */
  usesHostTimeZone?: boolean
) {
  if (!value) return "";

  const date = dayjs(value);

  if (asUTC) {
    return date.utc().format(format);
  }

  if (usesHostTimeZone) {
    return date.format(format);
  }

  return date
    .tz(APP_REGION === "KR" ? "Asia/Seoul" : "Asia/Singapore")
    .format(format);
}

/**
 * 시간을 utc 포맷 (ISO 형식) 으로 변환.
 * @param value 변환할 원본 시간 데이터
 */
function toFormattedDateToUTCDate(value: string | Date | number | undefined) {
  if (!value) return "";

  const date = dayjs(value);

  return date.utc().toISOString();
}

/**
 * 시간을 timezone에 맞는 Date 로 변환.
 * @param value 변환할 원본 시간 데이터
 * @param timeZone
 */
function toFormattedDateToLocaleDate({
  value,
  timeZone,
}: {
  value: string | Date | number | undefined;
  timeZone: TimeZone;
}) {
  if (!value) return "";

  const date = dayjs(value);

  return date.tz(timeZone).toISOString();
}

function hasDayPassed(date: Date) {
  const now = dayjs();

  return now.diff(dayjs(date), "day") >= 1;
}

function getRemainedDays(from: string | Date | null, to: string | Date | null) {
  if (!from || !to) return 0;

  const target = dayjs(to);

  const difference = target.diff(from, "days");

  return difference;
}

function isToday(value?: string | Date | number) {
  if (!value) return false;

  const now = dayjs();
  const target = dayjs(value);

  const difference = now.diff(target, "days");

  return !difference;
}

function isBeforeToday(value?: string | Date | number) {
  if (!value) return false;

  const target = dayjs(value);
  const now = dayjs();

  const isBefore = target.isBefore(now, "day");

  return isBefore;
}

/**
 * baseDate가 targetDate보다 빠른 날짜인지 체크
 */
function isBeforeDate({
  baseDate,
  targetDate,
  unit,
}: {
  /** 기준 날짜 */
  baseDate: string | Date | number;
  /** 비교대상 날짜 */
  targetDate: string | Date | number;
  /** 비교단위 */
  unit: dayjs.OpUnitType;
}) {
  const base = dayjs(baseDate);
  const target = dayjs(targetDate);

  return base.isBefore(target, unit);
}

function isTodayOrBeforeToday(value?: string | Date | number) {
  if (!value) return false;

  const target = dayjs(value);
  const now = dayjs();

  const isBefore = target.diff(now, "days") <= 0;

  return isBefore;
}

const isSameDay = (date1: dayjs.ConfigType, date2: dayjs.ConfigType) => {
  return dayjs(date1).isSame(date2, "day");
};

const isWeekday = (date: Date) => {
  const day = date.getDay();

  return day === 0 || day === 6;
};

function getHolidayList() {
  const yearArr = new Array(5)
    .fill(0)
    .map((v, i) => new Date().getFullYear() + i);

  const holidayListWithYear = yearArr
    .map((year) => PUBLIC_HOLIDAY_LIST.map((date) => `${year}/${date}`))
    .reduce((acc, items) => {
      return acc.concat(items);
    }, []);

  return [
    ...holidayListWithYear,
    ...LUNAR_PUBLIC_HOLIDAY_LIST,
    ...SUBSTITUTE_HOLIDAY_LIST,
  ];
}

const MINUTE_AS_MILLISECONDS = 1000 * 60;

const HOUR_AS_MILLISECONDS = MINUTE_AS_MILLISECONDS * 60;

const DAY_AS_MILLISECONDS = HOUR_AS_MILLISECONDS * 24;

function getUnixTime(date: Date, type: "seconds" | "milliseconds") {
  if (type === "seconds") {
    return date.getTime() / 1000;
  }

  if (type === "milliseconds") {
    return date.getTime();
  }
}

const getTodayDateToLocaleDateStringKr = () => {
  const date = new Date();
  return date.toLocaleDateString("ko-KR");
};

/**
 * utc Date나 string(2022-04-18T04:14:05.371Z 포맷)을 보내면
 * 그 중 날짜부분만(시간 제외)고려 하여 timezone을 고려한 Date로 변환한다
 * (시간 부분이 무시됨에 유의한다)
 * @param utcDateTime
 * @param timeZone
 * @param when
 */
function transformUTCDateToLocalDateTime({
  utcDateTime,
  timeZone,
  when,
}: {
  utcDateTime: Date | string;
  timeZone: TimeZone;
  when: "start" | "end";
}): Date | undefined {
  if (!(utcDateTime && timeZone && when)) return;

  const dayByTimeZone = dayjs.tz(utcDateTime, timeZone);

  if (when === "start") {
    return dayByTimeZone.startOf("day").toDate();
  }

  if (when === "end") {
    return dayByTimeZone.endOf("day").toDate();
  }
}

const THIS_YEAR_AS_TWO_DIGITS = (() => {
  const thisYear = new Date().getFullYear().toString();
  return thisYear.substring(2, 4);
})();

function isInvalidDate(date: Date | string) {
  if (date instanceof Date && isNaN(date.valueOf())) {
    return true;
  }
  return false;
}

function addDate({
  date,
  value,
  unit,
}: {
  date: string | Date | number;
  value: number;
  unit: dayjs.ManipulateType;
}) {
  const target = dayjs(date);

  return target.add(value, unit).toDate();
}

function subtractDate({
  date,
  value,
  unit,
}: {
  date: string | Date | number;
  value: number;
  unit: dayjs.ManipulateType;
}) {
  const target = dayjs(date);

  return target.subtract(value, unit).toDate();
}

function getMonthFromToday(date: string) {
  const today = dayjs(date).format();
  const startOfMonth = dayjs().startOf("month").format();
  return { startDate: startOfMonth, endDate: today };
}

const isSameOrBeforeToday = (value: dayjs.ConfigType) => {
  if (!value) {
    return false;
  }

  const target = dayjs(value);
  const today = dayjs().endOf("day");

  return target.isSameOrBefore(today);
};

/**
 * 현재 시간이 해당 날짜의 자정 이전인지 여부를 반환
 */
const isSameOrBeforeEndOfDate = (value: dayjs.ConfigType) => {
  if (!value) {
    return false;
  }

  const now = dayjs();
  const endOfDate = dayjs(value).endOf("date");

  return now.isSameOrBefore(endOfDate);
};

const isWeekend = (date: dayjs.ConfigType) => {
  const day = dayjs(date).day();

  return day === 0 || day === 6;
};

const isSaturday = (date: dayjs.ConfigType) => {
  const day = dayjs(date).day();

  return day === 6;
};

const isHoliday = (date: dayjs.ConfigType) =>
  getHolidayList().some((holiday) => isSameDay(date, holiday));

const isBusinessDay = (date: Date) => !(isWeekend(date) || isHoliday(date));

const addBusinessDays = ({
  date,
  days,
}: {
  date: dayjs.ConfigType;
  days: number;
}) => {
  let result = date;
  let count = 0;

  while (count < days) {
    result = dayjs(result).add(1, "day").toDate();

    if (isBusinessDay(result)) {
      count++;
    }
  }

  return result;
};

const isAfterBusinessDays = ({
  baseDate,
  compareDate,
  days,
}: {
  baseDate: dayjs.ConfigType;
  compareDate?: dayjs.ConfigType;
  days: number;
}) => {
  const dateAfterBusinessDays = addBusinessDays({ date: baseDate, days });

  return dayjs(compareDate).isAfter(dateAfterBusinessDays);
};

// 로컬 시간을 적용하지 않고 그대로 포맷팅
const toFormattedDateWithoutLocalTime = (
  value: dayjs.ConfigType,
  format = "YYYY-MM-DD hh:mm:ss a"
) => {
  if (!value) {
    return "";
  }

  const date = dayjs.utc(value);

  return date.format(format);
};

/**
 * 특정 format의 date string을 받아 JS Date 객체를 반환
 * - dateFormat에 2 digit year("YY")는 사용할 수 없음에 유의
 */
const toJSDate = (date: string, dateFormat: string) => {
  return dayjs(date, dateFormat).toDate();
};

/**
 * `브라우저 기준의 0시`(midnight)을 `APP_DEFAULT_TIMEZONE 기준의 0시`로 변경해줌.
 * - react-datepicker는 타임존 지정을 지원하지 않기때문에(항상 브라우저 타임존을 사용함), 이 함수를 이용하여 APP_DEFAULT_TIMEZONE에 맞는 0시를 가져옴
 * @param browserMidnight 브라우저 기준의 midnight인 Date (ex. KST에서 1월 2일을 선택했다면, `2024-01-02T15:00:00.000Z`인 Date )
 *
 */
function changeBrowserMidnightToAppMidnight(browserMidnight: Date): Date {
  const date = dayjs(browserMidnight as Date);

  // APP_DEFAULT_TIMEZONE 기준의 UTC Offset
  const appDefaultTimezoneOffset = dayjs
    .tz(browserMidnight as Date)
    .utcOffset();

  /**
   * 브라우저 Timezone 기준의 UTC Offset
   * dayjs로 계산하는 appDefaultTimezoneOffset과 달리 시간이 빠르면 음수로 표현된다.
   */
  const browserTimezoneOffset = (browserMidnight as Date).getTimezoneOffset();

  const offsetDifference = appDefaultTimezoneOffset + browserTimezoneOffset;

  // 두 Timezone간 offset차이를 빼서 APP_DEFAULT_TIMEZONE기준의 midnight을 계산한다.
  const appMidnight = date
    .subtract(offsetDifference, "m")
    // timezone에서 초단위는 의미가 없으므로 초단위 이하는 0으로 셋팅한다.
    .set("s", 0)
    .set("ms", 0)
    .toDate();

  return appMidnight;
}

/**
 * `APP_DEFAULT_TIMEZONE 기준의 0시`(midnight)을 `그날의 마지막 시간`으로 변경해줌
 * - 날짜 필터 중 `종료날짜`의 경우, 선택된 날짜의 마지막 시간을 가져와야 할때 사용한다.
 * - ex) `2024-01-03 (KST)`를 선택한 경우, 해당날의 0시 Date(`2024-01-02T15:00:00.000Z`)를 보내면, 그날의 마지막 시간인 `2024-01-03T14:59:59.999Z`을 반환.
 * @param appMidnight APP_DEFAULT_TIMEZONE 기준의 midnight인 Date (ex. KST에서 23년 1월 3일을 선택했다면, `2024-01-02T15:00:00.000Z`인 Date )
 */
function changeAppMidnightToEndOfDay(appMidnight: Date): Date {
  return dayjs(appMidnight).add(1, "d").subtract(1, "ms").toDate();
}

/**
 * `브라우저 Timezone 기준으로 오늘 0시`(midnight)를 반환
 */
function getBrowserTodayMidnight(): Date {
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  return now;
}

/**
 * `APP_DEFAULT_TIMEZONE 기준으로 오늘 0시`(midnight)를 반환
 */
function getAppTodayMidnight() {
  return changeBrowserMidnightToAppMidnight(getBrowserTodayMidnight());
}

/**
 * `APP_DEFAULT_TIMEZONE 기준으로 내일 0시`(midnight)를 반환
 */
function getAppTomorrowMidnight() {
  const appTodayMidnight = changeBrowserMidnightToAppMidnight(
    getBrowserTodayMidnight()
  );

  return dayjs(appTodayMidnight).add(1, "day").toDate();
}

/**
 * 날짜의 차이를 구해주는 함수.
 */
const getDifferentDate = (
  startDate: string | Date | number,
  endDate: string | Date | number,
  unit: "second" | "minute" | "hour" | "day" | "month" | "year"
) => {
  return dayjs(endDate).diff(dayjs(startDate), unit);
};

const convertToKstMidnightUTC = (date: string) => {
  const kstTime = dayjs(date).tz("Asia/Seoul");

  const kstMidnight = kstTime.startOf("day");

  return kstMidnight.utc().format();
};

export {
  hasDayPassed,
  changeBrowserMidnightToAppMidnight,
  changeAppMidnightToEndOfDay,
  addDate,
  subtractDate,
  getExpiredAt,
  toFormattedDate,
  toFormattedDateToUTCDate,
  toFormattedDateToLocaleDate,
  toJSDate,
  isToday,
  isBeforeToday,
  isTodayOrBeforeToday,
  isBeforeDate,
  isSameDay,
  isWeekday,
  isSaturday,
  getHolidayList,
  getRemainedDays,
  MINUTE_AS_MILLISECONDS,
  HOUR_AS_MILLISECONDS,
  DAY_AS_MILLISECONDS,
  getUnixTime,
  getTodayDateToLocaleDateStringKr,
  transformUTCDateToLocalDateTime,
  THIS_YEAR_AS_TWO_DIGITS,
  isInvalidDate,
  getMonthFromToday,
  isSameOrBeforeToday,
  isSameOrBeforeEndOfDate,
  isWeekend,
  isHoliday,
  isBusinessDay,
  addBusinessDays,
  isAfterBusinessDays,
  toFormattedDateWithoutLocalTime,
  getBrowserTodayMidnight,
  getAppTodayMidnight,
  getAppTomorrowMidnight,
  getDifferentDate,
  convertToKstMidnightUTC,
};
