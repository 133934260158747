let lastId = 0;

/**
 * 1씩 증가하는 id를 생성.
 * html label id 처럼 한 페이지 내에서만 unique하면 되는 단순한 상황에서 사용.
 * 주의: prefix에 띠라 Application 전체에서 unique할 수 도 있으나 보장은 안 되므로, 완벽한 unique를 원한다면 uuid 라이브러리를 사용해야함
 */
export default function newId(prefix = "id") {
  lastId++;
  return `${prefix}${lastId}`;
}
