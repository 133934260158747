// TODO: 도메인별로 분리해야함 (src/hooks/하위로)

import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useCallback } from "react";
import { useMediaQuery } from "react-responsive";

import { IS_ON_BROWSER } from "../../constants";
import {
  DESKTOP_FULL_WIDTH,
  MOBILE_MAX_WIDTH,
  TABLET_MAX_WIDTH,
} from "../../constants/common/common";
import { deepEqualObjectOrArray } from "./etc";

export function useDeepCompareMemoize<T>(value: T): T {
  const ref = useRef<T>();

  if (
    ref.current === undefined ||
    !deepEqualObjectOrArray(value, ref.current)
  ) {
    ref.current = value;
  }

  return ref.current;
}

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const debouncedMemoizedValue = useDeepCompareMemoize(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(debouncedMemoizedValue);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [debouncedMemoizedValue, delay]);

  return debouncedValue;
}

export function usePrevious<Value>(value: Value) {
  const ref = useRef<Value>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * focus된 dom을 반환
 */
export function useActiveElement() {
  if (!IS_ON_BROWSER) {
    return;
  }

  const [active, setActive] = useState(document.activeElement);

  const handleFocusIn = () => {
    setActive(document.activeElement);
  };

  const handleFocusOut = () => {
    setActive(document.activeElement);
  };

  React.useEffect(() => {
    document.addEventListener("focusin", handleFocusIn);
    document.addEventListener("focusout", handleFocusOut);
    return () => {
      document.removeEventListener("focusin", handleFocusIn);
      document.removeEventListener("focusout", handleFocusOut);
    };
  }, []);

  return active;
}

/**
 * 특정 dom이 focus되었는지를 확인
 * @param targetRef
 */
export function useIsTargetFocused(targetRef: React.MutableRefObject<any>) {
  if (!IS_ON_BROWSER) {
    return;
  }

  const [isFocused, setIsFocused] = useState(false);

  const handleFocusIn = () => {
    setIsFocused(targetRef.current === document.activeElement);
  };

  const handleFocusOut = () => {
    setIsFocused(targetRef.current === document.activeElement);
  };

  React.useEffect(() => {
    document.addEventListener("focusin", handleFocusIn);
    document.addEventListener("focusout", handleFocusOut);
    return () => {
      document.removeEventListener("focusin", handleFocusIn);
      document.removeEventListener("focusout", handleFocusOut);
    };
  }, []);

  return isFocused;
}

export interface ClientSidePagingFilter<T> {
  /**
   * keyof T 만쓰려고 했는데, generic T마다 동적으로 타입을 체크할 방법을 찾지못해 string[]를 포함시킴
   * 특정 타입에 없는 key가 추가되도 에러가 나지 않으므로 일단 이렇게 사용
   */
  key: (keyof T)[] | string[];
  type: "includes" | "includesCaseSensitive";
  term?: string;
}

/**
 * 클라이언트 사이드에서 페이징 (서버로부터 데이터를 한 번에 모두 받고 페이징)을 할때 사용
 * page가 1부터 시작함에 유의
 */
export function useClientSidePaging<T>(sizePerPage: number, dataSource: T[]) {
  const [pageNum, setPageNum] = useState(1);

  const [filter, setFilter] = useState(
    null as unknown as ClientSidePagingFilter<T> | null
  );

  const targetData = useMemo(getTargetData, [dataSource, filter]);

  const { totalCount, pageSize } = useMemo(getPageInfo, [targetData]);

  const pageData = useMemo(getPageData, [pageNum, targetData]);

  useEffect(() => {
    if (!dataSource?.length) {
      setPageNum(1);
    }
  }, [dataSource]);

  return {
    totalCount,
    pageSize,
    pageNum,
    setPageNum: handleSetPageNum,
    pageData,
    filter,
    setFilter,
  };

  function getPageData() {
    const startIndex = (pageNum - 1) * sizePerPage;
    const endIndex = startIndex + sizePerPage;

    return targetData.slice(startIndex, endIndex);
  }

  function handleSetPageNum(page: number) {
    if (page > 0 && page <= pageSize) {
      setPageNum(page);
    }
  }

  function getPageInfo() {
    const totalCount = targetData.length;
    const pageSize = Math.ceil(totalCount / sizePerPage) || 0;

    return {
      totalCount,
      pageSize,
    };
  }

  function getTargetData() {
    let result = dataSource || [];

    if (filter?.term) {
      result = dataSource.filter((v: any) => {
        if (filter.type === "includes") {
          return filter.key.some(
            (f: keyof T | string) =>
              v[f] && v[f].toUpperCase().includes(filter.term!.toUpperCase())
          );
        }

        if (filter.type === "includesCaseSensitive") {
          return filter.key.some(
            (f: keyof T | string) => v[f] && v[f].includes(filter.term)
          );
        }
      });
    }

    return result;
  }
}

/**
 * isMobile: CSS 미디어쿼리와 같이 max-width체크
 */
export function useCheckIsMobile() {
  const [isBrowser, setIsBrowser] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: MOBILE_MAX_WIDTH });

  useLayoutEffect(() => {
    if (typeof window !== "undefined") setIsBrowser(true);
  }, []);

  return {
    isMobile: isBrowser ? isMobile : false,
    /** 현재 View 사이즈가 모바일 환경의 사이즈(767.98px 미만) 인지 여부 */
    isMobileWidth: isMobile,
    isBrowser,
  };
}

/**
 * @deprecated 태블릿 사이즈는 지원하지 않으므로 사용하지 말것
 * TODO: 사용코드가 없어지면 삭제
 * isTablet: CSS 미디어쿼리와 같이 max-width체크
 */
export function useCheckIsTablet() {
  const isBrowser = Boolean(typeof window !== "undefined");

  const isTablet = useMediaQuery({ maxWidth: TABLET_MAX_WIDTH });

  return {
    isTablet: isBrowser ? isTablet : false,
    isBrowser,
  };
}

/**
 * isDesktop: CSS 미디어쿼리와 같이 min-width체크
 */
export function useCheckIsDesktop() {
  const isBrowser = Boolean(typeof window !== "undefined");

  const isDesktop = useMediaQuery({ minWidth: MOBILE_MAX_WIDTH });

  return {
    isDesktop: isBrowser ? isDesktop : false,
    isBrowser,
  };
}

export function useCheckIsMoreThanDesktopFullWidth() {
  const [isBrowser, setIsBrowser] = useState(false);

  const isMoreThanDesktopFullWidth = useMediaQuery({
    minWidth: DESKTOP_FULL_WIDTH,
  });

  useLayoutEffect(() => {
    if (typeof window !== "undefined") setIsBrowser(true);
  }, []);

  return {
    isMoreThanDesktopFullWidth: isBrowser ? isMoreThanDesktopFullWidth : false,
    isBrowser,
  };
}

/**
 * @deprecated useCheckIsMobile으로 대체
 * TODO: 모두 대체되면 삭제
 */
export function useDeviceDetect() {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const userAgent =
      // eslint-disable-next-line no-undef
      typeof window.navigator === "undefined" ? "" : window.navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    setMobile(mobile);
  }, []);

  return { isMobile };
}

export type UseCounterData<OTHERS> = {
  [P in number | string]: UseCounterDataValue<OTHERS>;
};

export interface UseCounterReturn<OTHERS> {
  initCounterInfo: (data: UseCounterData<OTHERS>) => void;
  counterInfo: UseCounterData<OTHERS>;
  resetCountById: (id: string | number) => void;
  addCountById: (id: string | number) => void;
  addCountByInput: ({ id, count }: { id: string; count: number }) => void;
}

export type UseCounterDataValue<OTHERS> = {
  // 반품관리 > 검수 > 관리일자를 설정한 상품의 경우 관리일자가 확정된 상태가 아니므로 max가 존재하지 않음
  max: number | undefined;
  current?: number;
} & OTHERS;
/**
 * counting하는데 사용 (ex. boful에서 스캔된 수를 카운팅 할때)
 */
export function useCounter<OTHERS>(
  initialData?: UseCounterData<OTHERS>
): UseCounterReturn<OTHERS> {
  const [counterInfo, setCounterInfo] = useState<UseCounterData<OTHERS>>(
    initialData || {}
  );

  const initCounterInfo = useCallback((data: UseCounterData<OTHERS>) => {
    setCounterInfo(data);
  }, []);

  const resetCountById = useCallback((id: string | number) => {
    setCounterInfo((prev) => {
      const next = { ...prev };

      if (next[id]) {
        const itemToUpdate = { ...next[id] };

        itemToUpdate.current = 0;

        next[id] = itemToUpdate;
      }

      return next;
    });
  }, []);

  const addCountById = useCallback((id: string | number) => {
    setCounterInfo((prev) => {
      const next = { ...prev };

      if (next[id]) {
        const itemToUpdate = { ...next[id] };

        itemToUpdate.current = next[id].current ? next[id].current! + 1 : 1;

        next[id] = itemToUpdate;
      }

      return next;
    });
  }, []);

  const addCountByInput = useCallback(
    ({ id, count }: { id: string; count: number }) => {
      setCounterInfo((prev) => {
        const next = { ...prev };

        if (next[id]) {
          const itemToUpdate = { ...next[id] };

          itemToUpdate.current = count;

          next[id] = itemToUpdate;
        }

        return next;
      });
    },
    []
  );

  return {
    counterInfo,

    initCounterInfo,
    resetCountById,
    addCountById,
    addCountByInput,
  };
}

/**
 * 웹 브라우저에서 스캐너의 스캔결과를 사용할 때 사용하는 hook.
 * 모바일 앱(ex. PDA)에서는 사용 방식이 다르다 (브릿지를 이용한 useScan hook을 사용)
 * @param handleScanResult
 */
export function useBrowserScan(handleScanResult: (result: string) => void) {
  const [collectedStr, setCollectedStr] = useState("");

  // 바코드 스캐너로 값이 입력될때는 100ms정도미만의 term을 두고 온다고 가정함.
  const debouncedCollectedStr = useDebounce(collectedStr, 100);

  useEffect(() => {
    window.document.addEventListener("keypress", handleKeyPress);

    return () =>
      window.document.removeEventListener("keypress", handleKeyPress);
  }, []);

  useEffect(() => {
    processResult(String(debouncedCollectedStr).toUpperCase());
  }, [debouncedCollectedStr]);

  function handleKeyPress(e: KeyboardEvent) {
    setCollectedStr((str) => str + e.key);
  }

  function processResult(result: string) {
    if (result) handleScanResult(result);

    // * 디버깅용 콘솔 출력
    console.log(`%c@스캔결과: ${result}`, "color: #F08080");

    setCollectedStr("");
  }
}

/**
 *
 * 컴포넌트를 강제로 업데이트 해야할 때 사용
 */
export function useForceUpdate() {
  const [value, setValue] = useState(0);
  return {
    forceUpdate: () => setValue((value) => value + 1),
  };
}

export function useWindowResize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
}
