import { Currency } from "../../types/common/common";
import {
  ApplyBidFeeData,
  FreightPaymentType,
} from "../../types/forwarding/adminBid";
import {
  TemplateCategory,
  TemplateDetailForRequest,
} from "../../types/forwarding/adminTemplate";
import {
  ExchangeRate,
  WithdrawalFormDetail,
} from "../../types/forwarding/trello";

import { GET_ADMIN_TEMPLATE_DETAIL_RES } from "../../api-interfaces/shipda-api/adminTemplate";

const getAllTemplateData = (
  templateData: GET_ADMIN_TEMPLATE_DETAIL_RES | undefined,
  itemCategory: TemplateCategory,
  exchangeRateList: ExchangeRate[]
) => {
  const templateTypeData = templateData?.filter((template) => {
    return template.itemCategory === itemCategory;
  });

  return getTemplateData(templateTypeData, exchangeRateList);
};

const getWithdrawalTemplateData = (
  templateData: GET_ADMIN_TEMPLATE_DETAIL_RES | undefined,
  exchangeRateList: ExchangeRate[]
) => {
  if (!templateData) return [];

  return templateData.map((template) => {
    const totalPrice = Number(
      (
        template.unitPrice *
        template.amount *
        getCurrencyRate(exchangeRateList, template.currency)
      ).toFixed(0)
    );

    const vatPrice = template.vatPrice
      ? Number((totalPrice / 10).toFixed(0))
      : 0;

    return {
      id: template.id,
      name: template.name,
      unitPrice: template.unitPrice ? Number(template.unitPrice) : 0,
      currency: template.currency,
      amount: template.amount,
      finalPrice: totalPrice + vatPrice,
      itemUnitMeasurement: template.itemUnitMeasurement,
      isVAT: template.isVAT,
      totalPrice: totalPrice,
      vatPrice: vatPrice,
      atCost: false,
      note: template.note || "",
    };
  });
};

const getCurrencyRate = (
  exchangeRateList: ExchangeRate[],
  currency: Currency
) => {
  return (
    exchangeRateList.find((exchangeRate) => {
      return exchangeRate.currency === currency;
    })?.rate || 1
  );
};

const getTemplateData = (
  templateData: GET_ADMIN_TEMPLATE_DETAIL_RES | undefined,
  exchangeRateList: ExchangeRate[]
) => {
  if (!templateData) return [];

  return templateData.map((template) => {
    return {
      key: template.key,
      item: template.name,
      unitPrice: template.unitPrice,
      currency: template.currency,
      amount: template.amount,
      // 항상 최신환율 값으로 재갱신
      itemPrice: Math.floor(
        template.unitPrice *
          template.amount *
          getCurrencyRate(exchangeRateList, template.currency)
      ),
      itemUnitMeasurement: template.itemUnitMeasurement,
      atCost: template.atCost,
      note: template.note || "",
      isVAT: template.isVAT,
      // 견적서에서 isTaxExempt는 실제로 사용하지 않고 false 고정 마이그레이션이 늦게 진행될 것 같아서 미리 false를 준다.
      isTaxExempt: template.isTaxExempt ?? false,
    };
  });
};

const getTemplateItemForRequest = (
  templateCategory: TemplateCategory,
  feeData: ApplyBidFeeData[]
): TemplateDetailForRequest[] => {
  if (feeData.length > 0) {
    return feeData.map((feeItem) => {
      return {
        itemCategory: templateCategory,
        key: feeItem.key,
        itemName: feeItem.item,
        currency: feeItem.currency,
        unitPrice: feeItem.unitPrice,
        amount: feeItem.amount,
        estimateCost: feeItem.itemPrice ? feeItem.itemPrice.toString() : "",
        itemUnitMeasurement: feeItem.itemUnitMeasurement,
        note: feeItem.note,
        atCost: feeItem.atCost,
        isVAT: feeItem.isVAT,
        isTaxExempt: feeItem.isTaxExempt,
      };
    });
  }
  return [];
};

const changeWithdrawalDataToTemplateDetailForRequest = (
  templateCategory: TemplateCategory,
  feeData: WithdrawalFormDetail[]
): TemplateDetailForRequest[] => {
  if (feeData.length > 0) {
    return feeData.map((feeItem) => {
      return {
        itemCategory: templateCategory,
        vatPrice: feeItem.vatPrice,
        estimateCost: (feeItem.vatPrice + feeItem.totalPrice).toString(),
        key: feeItem.name || `${feeItem.id}`,
        itemName: feeItem?.name || "",
        currency: feeItem.currency,
        unitPrice: feeItem.unitPrice,
        amount: feeItem.amount,
        totalPrice: feeItem.totalPrice,
        itemUnitMeasurement: feeItem.itemUnitMeasurement,
        note: feeItem.note,
        isVAT: feeItem.isVAT,
        atCost: false,
      };
    });
  }
  return [];
};

const getAdminBidTemplateSavePayload = ({
  freightFare,
  domesticFare,
  inlandFare,
  localFare,
  otherFare,
  taxFare,
  saveStatus,
  liner,
  isTransit,
  leadtime,
  comment,
  expiredAt,
  bidId,
  category,
  templateName,
  freightPaymentType,
}: {
  freightFare: ApplyBidFeeData[];
  domesticFare: ApplyBidFeeData[];
  inlandFare: ApplyBidFeeData[];
  localFare: ApplyBidFeeData[];
  otherFare: ApplyBidFeeData[];
  taxFare: ApplyBidFeeData[];
  saveStatus: string;
  liner?: string;
  isTransit?: boolean;
  leadtime?: number;
  comment?: string;
  expiredAt?: Date;
  bidId: number;
  category: TemplateCategory;
  templateName: string;
  freightPaymentType?: FreightPaymentType;
}) => {
  const freightFeeData = getTemplateItemForRequest("freightCost", freightFare);
  const domesticFeeData = getTemplateItemForRequest(
    "domesticCost",
    domesticFare
  );
  const inlandFeeData = getTemplateItemForRequest("inlandCost", inlandFare);
  const localFeeData = getTemplateItemForRequest("localCost", localFare);
  const otherFeeData = getTemplateItemForRequest("otherCost", otherFare);
  const taxFeeData = getTemplateItemForRequest("tax", taxFare);

  return {
    saveStatus,
    bidId,
    freightPaymentType: category === "all" ? freightPaymentType : undefined,
    liner: category === "all" ? liner : undefined,
    isTransit: category === "all" ? isTransit : undefined,
    category,
    templateName,
    leadtime: category === "all" ? leadtime : undefined,
    comment: category === "all" ? comment : undefined,
    expiredAt: category === "all" ? expiredAt : undefined,
    templateItem: freightFeeData
      .concat(
        domesticFeeData,
        localFeeData,
        otherFeeData,
        taxFeeData,
        inlandFeeData
      )
      .filter((v) => {
        if (category === "all") {
          return v;
        }
        return v.itemCategory === category;
      }),
  };
};

const getAdminWithdrawalTemplateSavePayload = ({
  withdrawalData,
  saveStatus,
  bidId,
  category,
  templateName,
  bidAccountPayableId,
}: {
  withdrawalData: WithdrawalFormDetail[];
  saveStatus: string;
  bidId: number;
  category: TemplateCategory;
  templateName: string;
  bidAccountPayableId?: number;
}) => {
  return {
    saveStatus,
    bidId,
    bidAccountPayableId,
    category,
    templateName,
    templateItem: changeWithdrawalDataToTemplateDetailForRequest(
      category,
      withdrawalData
    ),
  };
};

const getChangeTemplateCategoryToKr = (category: TemplateCategory) => {
  switch (category) {
    case "all":
      return "전체";
    case "localCost":
      return "현지비용";
    case "freightCost":
      return "해상/항공운임";
    case "domesticCost":
      return "국내 부대비용";
    case "inlandCost":
      return "내륙운송";
    case "otherCost":
      return "기타비용";
    case "shipping":
      return "선사";
    case "foreign":
      return "해외파트너";
    case "domestic":
      return "국내파트너";
    case "customs":
      return "관세사";
    case "inland":
      return "내륙운송사";
    case "warehouse":
      return "창고파트너";
    case "apEtc":
      return "기타";
    case "customsDuty":
      return "세관";
    case "apAll":
      return "파트너 전체";
    case "tax":
      return "수입 세금";
    default:
      return "-";
  }
};

export {
  getTemplateItemForRequest,
  getTemplateData,
  getAllTemplateData,
  getAdminBidTemplateSavePayload,
  getAdminWithdrawalTemplateSavePayload,
  getWithdrawalTemplateData,
  getChangeTemplateCategoryToKr,
};
