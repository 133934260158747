import { UserRegisterType, UserType } from "../../types/common/user";
import { AdminGuideType } from "../../types/forwarding/adminBidUser";

import { AdminBidUserFreightType } from "../../api-interfaces/shipda-api/adminBidUser";

const changeUserTypeToKr = (type: UserType) => {
  switch (type) {
    case "forwarder":
      return "물류";
    case "customs":
      return "관세";
    case "consignee":
      return "수입";
    default:
      return "-";
  }
};

const changeUserRegisterTypeToKr = (type: UserRegisterType) => {
  switch (type) {
    case "externalChannel":
      return "외부채널";
    case "fclPromotion":
      return "F타사견적서-100불";
    default:
      return "구분 없음";
  }
};

const changeUserDBFreightTypeToKr = (type: AdminBidUserFreightType | null) => {
  switch (type) {
    case "FCL":
      return "FCL";
    case "oceanTicket":
      return "오션티켓";
    case "consolidation":
      return "특송";
    case "fulfillment":
      return "풀필먼트";
    case "export":
      return "수출";
    default:
      return "구분 없음";
  }
};

const getUserGuideName = (type: AdminGuideType) => {
  switch (type) {
    case "oceanTicketGuide":
      return "오션티켓 가이드";
    case "consolidationGuide":
      return "묶음운송 가이드";
    case "importProcessGuide":
      return "수입 프로세스 가이드";
    case "customerAttrition":
      return "가이드북 희망";
    case "CIPLSample":
      return "CI/PL 샘플";
    case "CIPLGuide":
      return "CI/PL 가이드";
    case "boful":
      return "보풀 가이드";
    case "fulfillmentIntroduction":
      return "쉽다 풀필먼트 소개서";
    case "fulfillmentEstimation":
      return "쉽다 풀필먼트 표준견적서";
    case "fulfillmentGuide":
      return "풀필먼트 소개서, 표준견적서 둘다";
    case "fulfillmentEventEstimation":
      return "쉽다 풀필먼트 이벤트견적서";
    case "fulfillmentEventGuide":
      return "풀필먼트 소개서, 표준견적서 둘다";
    case "serviceGuide":
      return "소개서";
    case "advancedServiceGuide":
      return "쉽다 서비스 소개서";
    case "serviceGuideBook":
      return "가이드북";
    case "allServiceGuide":
      return "소개서 모두 받기";
    case "GDC":
      return "GDC";
    default:
      return "-";
  }
};

const getUserGuideFieldCategory = (type: AdminGuideType): string => {
  const fulfillmentGuideList: AdminGuideType[] = [
    "boful",
    "fulfillmentEstimation",
    "fulfillmentEventEstimation",
    "fulfillmentEventGuide",
    "fulfillmentGuide",
    "fulfillmentIntroduction",
  ];

  return fulfillmentGuideList.includes(type) ? "풀필먼트" : "포워딩";
};

export {
  changeUserTypeToKr,
  changeUserRegisterTypeToKr,
  changeUserDBFreightTypeToKr,
  getUserGuideName,
  getUserGuideFieldCategory,
};
