import { getDifferentDate, toFormattedDate } from "../common/date";

// 시간을 제외하고 초과기간을 계산하기 위한 포맷.
const toFormattedDifferentDate = (date?: Date | null) =>
  toFormattedDate(date, "YYYY-MM-DD");

/**
 * 반입/반출/반납에 대한 각 항목별(DEM, DET, Storage) 프리타임 관련 날짜를 계산하는 함수.
 * @param occurrenceDate 프리타임이 시작되는 발생일
 * @param completeDate 완료일
 * @param LFD 프리타임 기한일
 * @returns 프리타임 기간, 발생일, 프리타임 기한일, 초과일.
 */
const calculateFreeTimeDate = (
  occurrenceDate: Date | null,
  completeDate: Date | null,
  LFD: Date | null
) => {
  const today = new Date();

  const formattedFreeTimeDate = toFormattedDate(LFD, "YY-MM-DD");

  // 프리타임 기한을 초과한 기간. (완료일이 아직 없는 경우, 현시간 기준)
  const exceededDuration = getDifferentDate(
    toFormattedDifferentDate(LFD) ?? 0,
    toFormattedDifferentDate(completeDate) || toFormattedDifferentDate(today),
    "day"
  );

  return {
    occurrenceDate,
    freeTimeDate: formattedFreeTimeDate,
    exceededDuration,
  };
};

/**
 * 프리타임 데드라인일자를 계산.
 * @param occurrenceDate 프리타임이 시작되는 발생일
 * @param completeDate 완료일
 * @param LFD 프리타임 기한일
 * @returns 현재일 기준으로 프리타임 기한일까지의 데드라인.
 */
const calculateLFDDeadline = (
  occurrenceDate: Date | null,
  completeDate: Date | null,
  LFD: Date | null
) => {
  // 발생일이 없어서 아직 시작하지 않았거나, 이미 완료된 것인지 확인.
  if (completeDate || !occurrenceDate) return;

  if (!LFD) return;

  const today = new Date();

  const deadline = getDifferentDate(
    toFormattedDifferentDate(today),
    toFormattedDifferentDate(LFD),
    "day"
  );

  return deadline;
};

export {
  toFormattedDifferentDate,
  calculateFreeTimeDate,
  calculateLFDDeadline,
};
