import {
  AdminPromotionDeactivateReason,
  AdminPromotionDetailStatus,
  AdminPromotionStatus,
} from "../../types/forwarding/adminPromotion";

export const PROMOTION_CONTAINER_FAQ: {
  question: string;
  answer: React.ReactNode;
}[] = [
  {
    question: "최초 지급되는 할인쿠폰과 중복적용이 가능한가요?",
    answer: <>프로모션이 적용된 의뢰 건은 쿠폰중복 적용이 불가합니다.</>,
  },
  {
    question: "견적서 업로드 후 며칠 이내 $100 할인된 견적을 확인할 수 있나요?",
    answer: (
      <>
        타사 견적서 업로드 후 2일 이내 [마이페이지 - 운송현황]에서 컨테이너별
        $100 할인된 견적을 확인할 수 있습니다. 견적이 제공되면 이메일을 통해서도
        별도 안내 됩니다.
      </>
    ),
  },
  {
    question: "할인보장이 안되는 경우가 있을까요?",
    answer: (
      <>
        업로드한 타사 견적서의 운임 유효기간이 지났거나, 타사의 견적서임이
        증빙되기 어려운 경우 할인보장이 적용되지 않습니다. 또한 직접 상품을
        수입하는 기업이 아닌, 물류사/관세사 등은 참여가 불가합니다.
      </>
    ),
  },
  {
    question: "현재 쉽다를 이용 중입니다. 본 프로모션 참여가 가능한가요?",
    answer: (
      <>
        실시간 견적조회를 통해 최저가 운임을 안내하고 있습니다. 타사 운임이
        쉽다에서 견적조회되는 비용보다 낮은 경우, 별도 안내주시면 추가 인하하여
        안내드립니다.
      </>
    ),
  },
];

export const changePromotionDeactivateReasonToKr = (
  value: AdminPromotionDeactivateReason | null
) => {
  switch (value) {
    case "freightTypeLcl":
      return "LCL";
    case "freightTypeAir":
      return "AIR";
    case "previousUser":
      return "쉽다 이용고객";
    case "specialContainer":
      return "특수컨테이너";
    default:
      return "-";
  }
};

export const changePromotionDetailStatusToKr = (
  value: AdminPromotionDetailStatus | null
) => {
  switch (value) {
    case "waiting":
      return "견적서 요청 중";
    case "missed":
      return "부재 중";
    case "unableQuotation":
      return "수입견적 제출불가";
    case "lostContact":
      return "연락 두절";
    case "cancellationRequest":
      return "고객의 취소요청";
    case "duplicate":
      return "중복신청";
    case "etc":
    default:
      return "-";
  }
};

export const changePromotionStatusToKr = (value: AdminPromotionStatus) => {
  switch (value) {
    case "inProgress":
      return "진행";
    case "waiting":
      return "대기";
    case "pending":
      return "보류";
    case "finished":
      return "완료";
    case "canceled":
      return "취소";
    default:
      return "-";
  }
};
