import { ServiceType } from "@sellernote/common-interfaces/dist/bid";

import {
  RealtimeQuotationAIR,
  RealtimeQuotationFCL,
  RealtimeQuotationLCL,
} from "../../../types/forwarding/quotation";
import {
  AIRLINE,
  VESSEL_CARRIER,
} from "../../../types/forwarding/vesselSchedule";
import newId from "../../../utils/common/newId";

import { GET_USER_BID_LIST_RES_LIST_ITEM } from "../../../api-interfaces/shipda-api/bid";
import { GET_PARTNER_DETAIL_RES } from "../../../api-interfaces/shipda-api/partner";
import {
  GET_AIR_QUOTATION_RES,
  GET_AIR_QUOTATION_RES_ITEM,
  GET_FCL_QUOTATION_RES,
  GET_FCL_QUOTATION_RES_ITEM,
  GET_LCL_QUOTATION_RES,
} from "../../../api-interfaces/shipda-api/quote";

const FCL_QUOTATION_LIST_FOR_SORTING_TEST: GET_FCL_QUOTATION_RES = [
  {
    schedule: {
      id: 63646,
      carrier: "HEUNG-A",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305140000",
      eta: "202305160000",
      transitTimeInDays: 3,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
    quote: null,
  },
  {
    schedule: {
      id: 57484,
      carrier: "CK",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305140000",
      eta: "202305160000",
      transitTimeInDays: 3,
      docCloseTime: "202305111600",
      cargoCloseTime: "202305111600",
      ts: false,
    },
    quote: null,
  },
  {
    schedule: {
      id: 55843,
      carrier: "SINOKOR",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305160000",
      eta: "202305190000",
      transitTimeInDays: 4,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 65462,
      carrier: "TAIYOUNG",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305160000",
      eta: "202305190000",
      transitTimeInDays: 4,
      docCloseTime: "202305181600",
      cargoCloseTime: "202305191600",
      ts: false,
    },
    quote: {
      freightType: "FCL",
      isTempPrice: false,
      fareId: 1751,
      userId: 399,
      cost: 2335392,
      containInland: false,
      exchangeRate: 1335.6,
      isExpectVAT: true,
      localFee: [],
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 1202040,
          unitPrice: 450,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 587664,
          unitPrice: 220,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 106848,
          unitPrice: 40,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 260000,
          unitPrice: 130000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 8840,
          unitPrice: 4420,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 70000,
          unitPrice: 35000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      id: 72954,
      fileKey: null,
      createdAt: "2023-05-10T02:41:17.000Z",
      searchEstimateId: 72954,
      fare: {
        id: 1751,
        expiredAt: "2023-06-30",
        liner: "태영상선(Taiyung Shipping)",
        linerId: 167,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "FCL",
        currency: {
          usd: "1335.6",
          euro: "1469.59",
          yuan: "193.05",
        },
        request: {
          fob: "FOB조건(상하이항 -> 인천항)",
          item: "we",
          load: "20DRY x 2대",
        },
        oceanFreight: [
          {
            category: "OCEAN FREIGHT(해상운임)",
            shipda: "450",
            quantity: "2",
            cost: "1,202,040",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "BAF & CAF(유류할증료&통화할증료)",
            shipda: "220",
            quantity: "2",
            cost: "587,664",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CRS(긴급비용할증료)",
            shipda: "40",
            quantity: "2",
            cost: "106,848",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "130,000",
            quantity: "2",
            cost: "260,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "WFG(부두사용료)",
            shipda: "4,420",
            quantity: "2",
            cost: "8,840",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
            shipda: "35,000",
            quantity: "2",
            cost: "70,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "D/O FEE(화물인도지시서)",
            shipda: "0",
            quantity: "1",
            cost: "0",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
        ],
        localCost: [],
        inlandCost: [],
        taxCost: [],
        otherCost: [],
        etc: [
          {
            category: "ROUTE(구간)",
            detail: "상하이항 -> 인천항",
          },
          {
            category: "LINER(선사)",
            detail: "태영상선(Taiyung Shipping)",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 06월 30일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 05월 16일 -> 2023년 05월 19일",
          },
          {
            category: "LEAD TIME(소요일)",
            detail: "4",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
        total: "2,335,392",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 2335392,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 2335392,
      inlandCost: 0,
      estimateLogId: 8137,
    },
  },
  {
    schedule: {
      id: 59410,
      carrier: "KMTC",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305160000",
      eta: "202305200000",
      transitTimeInDays: 5,
      docCloseTime: "202305141600",
      cargoCloseTime: null,
      ts: false,
    },
    quote: {
      freightType: "FCL",
      isTempPrice: false,
      fareId: 469,
      userId: 399,
      cost: 1133352,
      containInland: false,
      exchangeRate: 1335.6,
      isExpectVAT: true,
      localFee: [],
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 587664,
          unitPrice: 220,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 106848,
          unitPrice: 40,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 260000,
          unitPrice: 130000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 8840,
          unitPrice: 4420,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 70000,
          unitPrice: 35000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      id: 72954,
      fileKey: null,
      createdAt: "2023-05-10T02:41:17.000Z",
      searchEstimateId: 72954,
      fare: {
        id: 469,
        expiredAt: "2023-05-31",
        liner: "고려해운(KMTC)",
        linerId: 145,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "FCL",
        currency: {
          usd: "1335.6",
          euro: "1469.59",
          yuan: "193.05",
        },
        request: {
          fob: "FOB조건(상하이항 -> 인천항)",
          item: "we",
          load: "20DRY x 2대",
        },
        oceanFreight: [
          {
            category: "OCEAN FREIGHT(해상운임)",
            shipda: "0",
            quantity: "2",
            cost: "0",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "BAF & CAF(유류할증료&통화할증료)",
            shipda: "220",
            quantity: "2",
            cost: "587,664",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CRS(긴급비용할증료)",
            shipda: "40",
            quantity: "2",
            cost: "106,848",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "130,000",
            quantity: "2",
            cost: "260,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "WFG(부두사용료)",
            shipda: "4,420",
            quantity: "2",
            cost: "8,840",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
            shipda: "35,000",
            quantity: "2",
            cost: "70,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "D/O FEE(화물인도지시서)",
            shipda: "0",
            quantity: "1",
            cost: "0",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
        ],
        localCost: [],
        inlandCost: [],
        taxCost: [],
        otherCost: [],
        etc: [
          {
            category: "ROUTE(구간)",
            detail: "상하이항 -> 인천항",
          },
          {
            category: "LINER(선사)",
            detail: "고려해운(KMTC)",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 05월 31일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 05월 16일 -> 2023년 05월 20일",
          },
          {
            category: "LEAD TIME(소요일)",
            detail: "5",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
        total: "1,133,352",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 1133352,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 1133352,
      inlandCost: 0,
      estimateLogId: 8137,
    },
  },
  {
    schedule: {
      id: 65913,
      carrier: "PANCON",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305180000",
      eta: "202305210000",
      transitTimeInDays: 4,
      docCloseTime: "202305141600",
      cargoCloseTime: "202305161600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 65461,
      carrier: "TAIYOUNG",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305210000",
      eta: "202305230000",
      transitTimeInDays: 3,
      docCloseTime: "202305151600",
      cargoCloseTime: "202305161600",
      ts: false,
    },
    quote: {
      freightType: "FCL",
      isTempPrice: false,
      fareId: 1751,
      userId: 399,
      cost: 2335392,
      containInland: false,
      exchangeRate: 1335.6,
      isExpectVAT: true,
      localFee: [],
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 1202040,
          unitPrice: 450,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 587664,
          unitPrice: 220,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 106848,
          unitPrice: 40,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 260000,
          unitPrice: 130000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 8840,
          unitPrice: 4420,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 70000,
          unitPrice: 35000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      id: 72954,
      fileKey: null,
      createdAt: "2023-05-10T02:41:17.000Z",
      searchEstimateId: 72954,
      fare: {
        id: 1751,
        expiredAt: "2023-06-30",
        liner: "태영상선(Taiyung Shipping)",
        linerId: 167,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "FCL",
        currency: {
          usd: "1335.6",
          euro: "1469.59",
          yuan: "193.05",
        },
        request: {
          fob: "FOB조건(상하이항 -> 인천항)",
          item: "we",
          load: "20DRY x 2대",
        },
        oceanFreight: [
          {
            category: "OCEAN FREIGHT(해상운임)",
            shipda: "450",
            quantity: "2",
            cost: "1,202,040",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "BAF & CAF(유류할증료&통화할증료)",
            shipda: "220",
            quantity: "2",
            cost: "587,664",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CRS(긴급비용할증료)",
            shipda: "40",
            quantity: "2",
            cost: "106,848",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "130,000",
            quantity: "2",
            cost: "260,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "WFG(부두사용료)",
            shipda: "4,420",
            quantity: "2",
            cost: "8,840",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
            shipda: "35,000",
            quantity: "2",
            cost: "70,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "D/O FEE(화물인도지시서)",
            shipda: "0",
            quantity: "1",
            cost: "0",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
        ],
        localCost: [],
        inlandCost: [],
        taxCost: [],
        otherCost: [],
        etc: [
          {
            category: "ROUTE(구간)",
            detail: "상하이항 -> 인천항",
          },
          {
            category: "LINER(선사)",
            detail: "태영상선(Taiyung Shipping)",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 06월 30일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 05월 21일 -> 2023년 05월 23일",
          },
          {
            category: "LEAD TIME(소요일)",
            detail: "3",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
        total: "2,335,392",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 2335392,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 2335392,
      inlandCost: 0,
      estimateLogId: 8137,
    },
  },
  {
    schedule: {
      id: 63649,
      carrier: "HEUNG-A",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305210000",
      eta: "202305230000",
      transitTimeInDays: 3,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 57485,
      carrier: "CK",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305210000",
      eta: "202305230000",
      transitTimeInDays: 3,
      docCloseTime: "202305171600",
      cargoCloseTime: "202305181600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 65912,
      carrier: "PANCON",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305210000",
      eta: "202305230000",
      transitTimeInDays: 3,
      docCloseTime: "202305171600",
      cargoCloseTime: "202305181600",
      ts: false,
    },
  },
  {
    // 최저가이지만 스케줄은 없는 경우
    quote: {
      freightType: "FCL",
      isTempPrice: false,
      fareId: 469,
      userId: 399,
      cost: 1033352,
      containInland: false,
      exchangeRate: 1335.6,
      isExpectVAT: true,
      localFee: [],
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 487664,
          unitPrice: 220,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 106848,
          unitPrice: 40,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 260000,
          unitPrice: 130000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 8840,
          unitPrice: 4420,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 70000,
          unitPrice: 35000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      id: 72954,
      fileKey: null,
      createdAt: "2023-05-10T02:41:17.000Z",
      searchEstimateId: 72954,
      fare: {
        id: 469,
        expiredAt: "2023-05-31",
        liner: "고려해운(KMTC)",
        linerId: 145,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "FCL",
        currency: {
          usd: "1335.6",
          euro: "1469.59",
          yuan: "193.05",
        },
        request: {
          fob: "FOB조건(상하이항 -> 인천항)",
          item: "we",
          load: "20DRY x 2대",
        },
        oceanFreight: [
          {
            category: "OCEAN FREIGHT(해상운임)",
            shipda: "0",
            quantity: "2",
            cost: "0",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "BAF & CAF(유류할증료&통화할증료)",
            shipda: "220",
            quantity: "2",
            cost: "587,664",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CRS(긴급비용할증료)",
            shipda: "40",
            quantity: "2",
            cost: "106,848",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "130,000",
            quantity: "2",
            cost: "260,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "WFG(부두사용료)",
            shipda: "4,420",
            quantity: "2",
            cost: "8,840",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
            shipda: "35,000",
            quantity: "2",
            cost: "70,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "D/O FEE(화물인도지시서)",
            shipda: "0",
            quantity: "1",
            cost: "0",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
        ],
        localCost: [],
        inlandCost: [],
        taxCost: [],
        otherCost: [],
        etc: [
          {
            category: "ROUTE(구간)",
            detail: "상하이항 -> 인천항",
          },
          {
            category: "LINER(선사)",
            detail: "고려해운(KMTC)",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 05월 31일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 05월 21일 -> 2023년 05월 23일",
          },
          {
            category: "LEAD TIME(소요일)",
            detail: "3",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
        total: "1,133,352",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 1133352,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 1133352,
      inlandCost: 0,
      estimateLogId: 8137,
    },
  },
  {
    schedule: {
      id: 59409,
      carrier: "KMTC",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRICN",
      podPortName: "NEW INCHEON",
      etd: "202305220000",
      eta: "202305230000",
      transitTimeInDays: 2,
      docCloseTime: "202305181600",
      cargoCloseTime: null,
      ts: false,
    },
    quote: {
      freightType: "FCL",
      isTempPrice: false,
      fareId: 15004,
      userId: 399,
      cost: 1216844,
      containInland: false,
      exchangeRate: 1335.6,
      isExpectVAT: true,
      localFee: [],
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 93492,
          unitPrice: 35,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 587664,
          unitPrice: 220,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 106848,
          unitPrice: 40,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 260000,
          unitPrice: 130000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 8840,
          unitPrice: 4420,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 70000,
          unitPrice: 35000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 40000,
          unitPrice: 40000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      id: 72954,
      fileKey: null,
      createdAt: "2023-05-10T02:41:17.000Z",
      searchEstimateId: 72954,
      fare: {
        id: 15004,
        expiredAt: "2023-05-31",
        liner: "고려해운(KMTC)",
        linerId: 145,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "FCL",
        currency: {
          usd: "1335.6",
          euro: "1469.59",
          yuan: "193.05",
        },
        request: {
          fob: "FOB조건(상하이항 -> 인천신항)",
          item: "we",
          load: "20DRY x 2대",
        },
        oceanFreight: [
          {
            category: "OCEAN FREIGHT(해상운임)",
            shipda: "35",
            quantity: "2",
            cost: "93,492",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "BAF & CAF(유류할증료&통화할증료)",
            shipda: "220",
            quantity: "2",
            cost: "587,664",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CRS(긴급비용할증료)",
            shipda: "40",
            quantity: "2",
            cost: "106,848",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "130,000",
            quantity: "2",
            cost: "260,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "WFG(부두사용료)",
            shipda: "4,420",
            quantity: "2",
            cost: "8,840",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
            shipda: "35,000",
            quantity: "2",
            cost: "70,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "40,000",
            quantity: "1",
            cost: "40,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "D/O FEE(화물인도지시서)",
            shipda: "0",
            quantity: "1",
            cost: "0",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
        ],
        localCost: [],
        inlandCost: [],
        taxCost: [],
        otherCost: [],
        etc: [
          {
            category: "ROUTE(구간)",
            detail: "상하이항 -> 인천신항",
          },
          {
            category: "LINER(선사)",
            detail: "고려해운(KMTC)",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 05월 31일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 05월 22일 -> 2023년 05월 23일",
          },
          {
            category: "LEAD TIME(소요일)",
            detail: "2",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
        total: "1,216,844",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 1216844,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 1216844,
      inlandCost: 0,
      estimateLogId: 8137,
    },
  },
  {
    schedule: {
      id: 65464,
      carrier: "TAIYOUNG",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305230000",
      eta: "202305260000",
      transitTimeInDays: 4,
      docCloseTime: "202305251600",
      cargoCloseTime: "202305261600",
      ts: false,
    },
    quote: {
      freightType: "FCL",
      isTempPrice: false,
      fareId: 1751,
      userId: 399,
      cost: 2335392,
      containInland: false,
      exchangeRate: 1335.6,
      isExpectVAT: true,
      localFee: [],
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 1202040,
          unitPrice: 450,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 587664,
          unitPrice: 220,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 106848,
          unitPrice: 40,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 260000,
          unitPrice: 130000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 8840,
          unitPrice: 4420,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 70000,
          unitPrice: 35000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      id: 72954,
      fileKey: null,
      createdAt: "2023-05-10T02:41:17.000Z",
      searchEstimateId: 72954,
      fare: {
        id: 1751,
        expiredAt: "2023-06-30",
        liner: "태영상선(Taiyung Shipping)",
        linerId: 167,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "FCL",
        currency: {
          usd: "1335.6",
          euro: "1469.59",
          yuan: "193.05",
        },
        request: {
          fob: "FOB조건(상하이항 -> 인천항)",
          item: "we",
          load: "20DRY x 2대",
        },
        oceanFreight: [
          {
            category: "OCEAN FREIGHT(해상운임)",
            shipda: "450",
            quantity: "2",
            cost: "1,202,040",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "BAF & CAF(유류할증료&통화할증료)",
            shipda: "220",
            quantity: "2",
            cost: "587,664",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CRS(긴급비용할증료)",
            shipda: "40",
            quantity: "2",
            cost: "106,848",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "130,000",
            quantity: "2",
            cost: "260,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "WFG(부두사용료)",
            shipda: "4,420",
            quantity: "2",
            cost: "8,840",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
            shipda: "35,000",
            quantity: "2",
            cost: "70,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "D/O FEE(화물인도지시서)",
            shipda: "0",
            quantity: "1",
            cost: "0",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
        ],
        localCost: [],
        inlandCost: [],
        taxCost: [],
        otherCost: [],
        etc: [
          {
            category: "ROUTE(구간)",
            detail: "상하이항 -> 인천항",
          },
          {
            category: "LINER(선사)",
            detail: "태영상선(Taiyung Shipping)",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 06월 30일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 05월 23일 -> 2023년 05월 26일",
          },
          {
            category: "LEAD TIME(소요일)",
            detail: "4",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
        total: "2,335,392",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 2335392,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 2335392,
      inlandCost: 0,
      estimateLogId: 8137,
    },
  },
  {
    schedule: {
      id: 65915,
      carrier: "PANCON",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305240000",
      eta: "202305260000",
      transitTimeInDays: 3,
      docCloseTime: "202305181600",
      cargoCloseTime: "202305221600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 59413,
      carrier: "KMTC",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305240000",
      eta: "202305270000",
      transitTimeInDays: 4,
      docCloseTime: "202305221600",
      cargoCloseTime: null,
      ts: false,
    },
    quote: {
      freightType: "FCL",
      isTempPrice: false,
      fareId: 469,
      userId: 399,
      cost: 1133352,
      containInland: false,
      exchangeRate: 1335.6,
      isExpectVAT: true,
      localFee: [],
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 587664,
          unitPrice: 220,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 106848,
          unitPrice: 40,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 260000,
          unitPrice: 130000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 8840,
          unitPrice: 4420,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 70000,
          unitPrice: 35000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      id: 72954,
      fileKey: null,
      createdAt: "2023-05-10T02:41:17.000Z",
      searchEstimateId: 72954,
      fare: {
        id: 469,
        expiredAt: "2023-05-31",
        liner: "고려해운(KMTC)",
        linerId: 145,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "FCL",
        currency: {
          usd: "1335.6",
          euro: "1469.59",
          yuan: "193.05",
        },
        request: {
          fob: "FOB조건(상하이항 -> 인천항)",
          item: "we",
          load: "20DRY x 2대",
        },
        oceanFreight: [
          {
            category: "OCEAN FREIGHT(해상운임)",
            shipda: "0",
            quantity: "2",
            cost: "0",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "BAF & CAF(유류할증료&통화할증료)",
            shipda: "220",
            quantity: "2",
            cost: "587,664",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CRS(긴급비용할증료)",
            shipda: "40",
            quantity: "2",
            cost: "106,848",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "130,000",
            quantity: "2",
            cost: "260,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "WFG(부두사용료)",
            shipda: "4,420",
            quantity: "2",
            cost: "8,840",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
            shipda: "35,000",
            quantity: "2",
            cost: "70,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "D/O FEE(화물인도지시서)",
            shipda: "0",
            quantity: "1",
            cost: "0",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
        ],
        localCost: [],
        inlandCost: [],
        taxCost: [],
        otherCost: [],
        etc: [
          {
            category: "ROUTE(구간)",
            detail: "상하이항 -> 인천항",
          },
          {
            category: "LINER(선사)",
            detail: "고려해운(KMTC)",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 05월 31일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 05월 24일 -> 2023년 05월 27일",
          },
          {
            category: "LEAD TIME(소요일)",
            detail: "4",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
        total: "1,133,352",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 1133352,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 1133352,
      inlandCost: 0,
      estimateLogId: 8137,
    },
  },
  {
    schedule: {
      id: 55844,
      carrier: "SINOKOR",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305240000",
      eta: "202305260000",
      transitTimeInDays: 3,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 63651,
      carrier: "HEUNG-A",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305280000",
      eta: "202305300000",
      transitTimeInDays: 3,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 65914,
      carrier: "PANCON",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305280000",
      eta: "202305300000",
      transitTimeInDays: 3,
      docCloseTime: "202305251600",
      cargoCloseTime: "202305261600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 59411,
      carrier: "KMTC",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRICN",
      podPortName: "NEW INCHEON",
      etd: "202305280000",
      eta: "202305300000",
      transitTimeInDays: 3,
      docCloseTime: "202305251600",
      cargoCloseTime: null,
      ts: false,
    },
    quote: {
      freightType: "FCL",
      isTempPrice: false,
      fareId: 15004,
      userId: 399,
      cost: 1216844,
      containInland: false,
      exchangeRate: 1335.6,
      isExpectVAT: true,
      localFee: [],
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 93492,
          unitPrice: 35,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 587664,
          unitPrice: 220,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 106848,
          unitPrice: 40,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 260000,
          unitPrice: 130000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 8840,
          unitPrice: 4420,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 70000,
          unitPrice: 35000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 40000,
          unitPrice: 40000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      id: 72954,
      fileKey: null,
      createdAt: "2023-05-10T02:41:17.000Z",
      searchEstimateId: 72954,
      fare: {
        id: 15004,
        expiredAt: "2023-05-31",
        liner: "고려해운(KMTC)",
        linerId: 145,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "FCL",
        currency: {
          usd: "1335.6",
          euro: "1469.59",
          yuan: "193.05",
        },
        request: {
          fob: "FOB조건(상하이항 -> 인천신항)",
          item: "we",
          load: "20DRY x 2대",
        },
        oceanFreight: [
          {
            category: "OCEAN FREIGHT(해상운임)",
            shipda: "35",
            quantity: "2",
            cost: "93,492",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "BAF & CAF(유류할증료&통화할증료)",
            shipda: "220",
            quantity: "2",
            cost: "587,664",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CRS(긴급비용할증료)",
            shipda: "40",
            quantity: "2",
            cost: "106,848",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "130,000",
            quantity: "2",
            cost: "260,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "WFG(부두사용료)",
            shipda: "4,420",
            quantity: "2",
            cost: "8,840",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
            shipda: "35,000",
            quantity: "2",
            cost: "70,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "40,000",
            quantity: "1",
            cost: "40,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "D/O FEE(화물인도지시서)",
            shipda: "0",
            quantity: "1",
            cost: "0",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
        ],
        localCost: [],
        inlandCost: [],
        taxCost: [],
        otherCost: [],
        etc: [
          {
            category: "ROUTE(구간)",
            detail: "상하이항 -> 인천신항",
          },
          {
            category: "LINER(선사)",
            detail: "고려해운(KMTC)",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 05월 31일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 05월 28일 -> 2023년 05월 30일",
          },
          {
            category: "LEAD TIME(소요일)",
            detail: "3",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
        total: "1,216,844",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 1216844,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 1216844,
      inlandCost: 0,
      estimateLogId: 8137,
    },
  },
  {
    schedule: {
      id: 59412,
      carrier: "KMTC",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305280000",
      eta: "202305300000",
      transitTimeInDays: 3,
      docCloseTime: "202305251600",
      cargoCloseTime: null,
      ts: false,
    },
    quote: {
      freightType: "FCL",
      isTempPrice: false,
      fareId: 469,
      userId: 399,
      cost: 1133352,
      containInland: false,
      exchangeRate: 1335.6,
      isExpectVAT: true,
      localFee: [],
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 587664,
          unitPrice: 220,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 106848,
          unitPrice: 40,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 260000,
          unitPrice: 130000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 8840,
          unitPrice: 4420,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 70000,
          unitPrice: 35000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      id: 72954,
      fileKey: null,
      createdAt: "2023-05-10T02:41:17.000Z",
      searchEstimateId: 72954,
      fare: {
        id: 469,
        expiredAt: "2023-05-31",
        liner: "고려해운(KMTC)",
        linerId: 145,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "FCL",
        currency: {
          usd: "1335.6",
          euro: "1469.59",
          yuan: "193.05",
        },
        request: {
          fob: "FOB조건(상하이항 -> 인천항)",
          item: "we",
          load: "20DRY x 2대",
        },
        oceanFreight: [
          {
            category: "OCEAN FREIGHT(해상운임)",
            shipda: "0",
            quantity: "2",
            cost: "0",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "BAF & CAF(유류할증료&통화할증료)",
            shipda: "220",
            quantity: "2",
            cost: "587,664",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CRS(긴급비용할증료)",
            shipda: "40",
            quantity: "2",
            cost: "106,848",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "130,000",
            quantity: "2",
            cost: "260,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "WFG(부두사용료)",
            shipda: "4,420",
            quantity: "2",
            cost: "8,840",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
            shipda: "35,000",
            quantity: "2",
            cost: "70,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "D/O FEE(화물인도지시서)",
            shipda: "0",
            quantity: "1",
            cost: "0",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
        ],
        localCost: [],
        inlandCost: [],
        taxCost: [],
        otherCost: [],
        etc: [
          {
            category: "ROUTE(구간)",
            detail: "상하이항 -> 인천항",
          },
          {
            category: "LINER(선사)",
            detail: "고려해운(KMTC)",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 05월 31일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 05월 28일 -> 2023년 05월 30일",
          },
          {
            category: "LEAD TIME(소요일)",
            detail: "3",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
        total: "1,133,352",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 1133352,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 1133352,
      inlandCost: 0,
      estimateLogId: 8137,
    },
  },
  {
    schedule: {
      id: 65463,
      carrier: "TAIYOUNG",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305280000",
      eta: "202305300000",
      transitTimeInDays: 3,
      docCloseTime: "202305221600",
      cargoCloseTime: "202305231600",
      ts: false,
    },
    quote: {
      freightType: "FCL",
      isTempPrice: false,
      fareId: 1751,
      userId: 399,
      cost: 2335392,
      containInland: false,
      exchangeRate: 1335.6,
      isExpectVAT: true,
      localFee: [],
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 1202040,
          unitPrice: 450,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 587664,
          unitPrice: 220,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 106848,
          unitPrice: 40,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 260000,
          unitPrice: 130000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 8840,
          unitPrice: 4420,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 70000,
          unitPrice: 35000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      id: 72954,
      fileKey: null,
      createdAt: "2023-05-10T02:41:17.000Z",
      searchEstimateId: 72954,
      fare: {
        id: 1751,
        expiredAt: "2023-06-30",
        liner: "태영상선(Taiyung Shipping)",
        linerId: 167,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "FCL",
        currency: {
          usd: "1335.6",
          euro: "1469.59",
          yuan: "193.05",
        },
        request: {
          fob: "FOB조건(상하이항 -> 인천항)",
          item: "we",
          load: "20DRY x 2대",
        },
        oceanFreight: [
          {
            category: "OCEAN FREIGHT(해상운임)",
            shipda: "450",
            quantity: "2",
            cost: "1,202,040",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "BAF & CAF(유류할증료&통화할증료)",
            shipda: "220",
            quantity: "2",
            cost: "587,664",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CRS(긴급비용할증료)",
            shipda: "40",
            quantity: "2",
            cost: "106,848",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "130,000",
            quantity: "2",
            cost: "260,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "WFG(부두사용료)",
            shipda: "4,420",
            quantity: "2",
            cost: "8,840",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
            shipda: "35,000",
            quantity: "2",
            cost: "70,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "D/O FEE(화물인도지시서)",
            shipda: "0",
            quantity: "1",
            cost: "0",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
        ],
        localCost: [],
        inlandCost: [],
        taxCost: [],
        otherCost: [],
        etc: [
          {
            category: "ROUTE(구간)",
            detail: "상하이항 -> 인천항",
          },
          {
            category: "LINER(선사)",
            detail: "태영상선(Taiyung Shipping)",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 06월 30일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 05월 28일 -> 2023년 05월 30일",
          },
          {
            category: "LEAD TIME(소요일)",
            detail: "3",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
        total: "2,335,392",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 2335392,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 2335392,
      inlandCost: 0,
      estimateLogId: 8137,
    },
  },
  {
    schedule: {
      id: 57486,
      carrier: "CK",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305280000",
      eta: "202305300000",
      transitTimeInDays: 3,
      docCloseTime: "202305251600",
      cargoCloseTime: "202305251600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 55845,
      carrier: "SINOKOR",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305310000",
      eta: "202306020000",
      transitTimeInDays: 3,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 65466,
      carrier: "TAIYOUNG",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305310000",
      eta: "202306020000",
      transitTimeInDays: 3,
      docCloseTime: "202306011600",
      cargoCloseTime: "202306021600",
      ts: false,
    },
    quote: {
      freightType: "FCL",
      isTempPrice: false,
      fareId: 1751,
      userId: 399,
      cost: 2335392,
      containInland: false,
      exchangeRate: 1335.6,
      isExpectVAT: true,
      localFee: [],
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 1202040,
          unitPrice: 450,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 587664,
          unitPrice: 220,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 106848,
          unitPrice: 40,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 260000,
          unitPrice: 130000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 8840,
          unitPrice: 4420,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 70000,
          unitPrice: 35000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      id: 72954,
      fileKey: null,
      createdAt: "2023-05-10T02:41:17.000Z",
      searchEstimateId: 72954,
      fare: {
        id: 1751,
        expiredAt: "2023-06-30",
        liner: "태영상선(Taiyung Shipping)",
        linerId: 167,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "FCL",
        currency: {
          usd: "1335.6",
          euro: "1469.59",
          yuan: "193.05",
        },
        request: {
          fob: "FOB조건(상하이항 -> 인천항)",
          item: "we",
          load: "20DRY x 2대",
        },
        oceanFreight: [
          {
            category: "OCEAN FREIGHT(해상운임)",
            shipda: "450",
            quantity: "2",
            cost: "1,202,040",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "BAF & CAF(유류할증료&통화할증료)",
            shipda: "220",
            quantity: "2",
            cost: "587,664",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CRS(긴급비용할증료)",
            shipda: "40",
            quantity: "2",
            cost: "106,848",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "130,000",
            quantity: "2",
            cost: "260,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "WFG(부두사용료)",
            shipda: "4,420",
            quantity: "2",
            cost: "8,840",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
            shipda: "35,000",
            quantity: "2",
            cost: "70,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "D/O FEE(화물인도지시서)",
            shipda: "0",
            quantity: "1",
            cost: "0",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
        ],
        localCost: [],
        inlandCost: [],
        taxCost: [],
        otherCost: [],
        etc: [
          {
            category: "ROUTE(구간)",
            detail: "상하이항 -> 인천항",
          },
          {
            category: "LINER(선사)",
            detail: "태영상선(Taiyung Shipping)",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 06월 30일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 05월 31일 -> 2023년 06월 02일",
          },
          {
            category: "LEAD TIME(소요일)",
            detail: "3",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
        total: "2,335,392",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 2335392,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 2335392,
      inlandCost: 0,
      estimateLogId: 8137,
    },
  },
  {
    schedule: {
      id: 65917,
      carrier: "PANCON",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202305310000",
      eta: "202306020000",
      transitTimeInDays: 3,
      docCloseTime: "202305251600",
      cargoCloseTime: "202305291600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 59416,
      carrier: "KMTC",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306020000",
      eta: "202306030000",
      transitTimeInDays: 2,
      docCloseTime: "202305311600",
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 65916,
      carrier: "PANCON",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306040000",
      eta: "202306060000",
      transitTimeInDays: 3,
      docCloseTime: "202306011600",
      cargoCloseTime: "202306021600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 57487,
      carrier: "CK",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306040000",
      eta: "202306060000",
      transitTimeInDays: 3,
      docCloseTime: "202306011600",
      cargoCloseTime: "202306011600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 65465,
      carrier: "TAIYOUNG",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306040000",
      eta: "202306060000",
      transitTimeInDays: 3,
      docCloseTime: "202305291600",
      cargoCloseTime: "202305301600",
      ts: false,
    },
    quote: {
      freightType: "FCL",
      isTempPrice: false,
      fareId: 1751,
      userId: 399,
      cost: 2335392,
      containInland: false,
      exchangeRate: 1335.6,
      isExpectVAT: true,
      localFee: [],
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 1202040,
          unitPrice: 450,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 587664,
          unitPrice: 220,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 106848,
          unitPrice: 40,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 260000,
          unitPrice: 130000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 8840,
          unitPrice: 4420,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 70000,
          unitPrice: 35000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      id: 72954,
      fileKey: null,
      createdAt: "2023-05-10T02:41:17.000Z",
      searchEstimateId: 72954,
      fare: {
        id: 1751,
        expiredAt: "2023-06-30",
        liner: "태영상선(Taiyung Shipping)",
        linerId: 167,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "FCL",
        currency: {
          usd: "1335.6",
          euro: "1469.59",
          yuan: "193.05",
        },
        request: {
          fob: "FOB조건(상하이항 -> 인천항)",
          item: "we",
          load: "20DRY x 2대",
        },
        oceanFreight: [
          {
            category: "OCEAN FREIGHT(해상운임)",
            shipda: "450",
            quantity: "2",
            cost: "1,202,040",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "BAF & CAF(유류할증료&통화할증료)",
            shipda: "220",
            quantity: "2",
            cost: "587,664",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CRS(긴급비용할증료)",
            shipda: "40",
            quantity: "2",
            cost: "106,848",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "130,000",
            quantity: "2",
            cost: "260,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "WFG(부두사용료)",
            shipda: "4,420",
            quantity: "2",
            cost: "8,840",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
            shipda: "35,000",
            quantity: "2",
            cost: "70,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "D/O FEE(화물인도지시서)",
            shipda: "0",
            quantity: "1",
            cost: "0",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
        ],
        localCost: [],
        inlandCost: [],
        taxCost: [],
        otherCost: [],
        etc: [
          {
            category: "ROUTE(구간)",
            detail: "상하이항 -> 인천항",
          },
          {
            category: "LINER(선사)",
            detail: "태영상선(Taiyung Shipping)",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 06월 30일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 06월 04일 -> 2023년 06월 06일",
          },
          {
            category: "LEAD TIME(소요일)",
            detail: "3",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
        total: "2,335,392",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 2335392,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 2335392,
      inlandCost: 0,
      estimateLogId: 8137,
    },
  },
  {
    schedule: {
      id: 59415,
      carrier: "KMTC",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306040000",
      eta: "202306060000",
      transitTimeInDays: 3,
      docCloseTime: "202306011600",
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 63654,
      carrier: "HEUNG-A",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306040000",
      eta: "202306060000",
      transitTimeInDays: 3,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 59414,
      carrier: "KMTC",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRICN",
      podPortName: "NEW INCHEON",
      etd: "202306050000",
      eta: "202306060000",
      transitTimeInDays: 2,
      docCloseTime: "202306011600",
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 65919,
      carrier: "PANCON",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306070000",
      eta: "202306090000",
      transitTimeInDays: 3,
      docCloseTime: "202306011600",
      cargoCloseTime: "202306051600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 65468,
      carrier: "TAIYOUNG",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306070000",
      eta: "202306090000",
      transitTimeInDays: 3,
      docCloseTime: "202306081600",
      cargoCloseTime: "202306091600",
      ts: false,
    },
    quote: {
      freightType: "FCL",
      isTempPrice: false,
      fareId: 1751,
      userId: 399,
      cost: 2335392,
      containInland: false,
      exchangeRate: 1335.6,
      isExpectVAT: true,
      localFee: [],
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 1202040,
          unitPrice: 450,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 587664,
          unitPrice: 220,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 106848,
          unitPrice: 40,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 260000,
          unitPrice: 130000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 8840,
          unitPrice: 4420,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 70000,
          unitPrice: 35000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      id: 72954,
      fileKey: null,
      createdAt: "2023-05-10T02:41:17.000Z",
      searchEstimateId: 72954,
      fare: {
        id: 1751,
        expiredAt: "2023-06-30",
        liner: "태영상선(Taiyung Shipping)",
        linerId: 167,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "FCL",
        currency: {
          usd: "1335.6",
          euro: "1469.59",
          yuan: "193.05",
        },
        request: {
          fob: "FOB조건(상하이항 -> 인천항)",
          item: "we",
          load: "20DRY x 2대",
        },
        oceanFreight: [
          {
            category: "OCEAN FREIGHT(해상운임)",
            shipda: "450",
            quantity: "2",
            cost: "1,202,040",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "BAF & CAF(유류할증료&통화할증료)",
            shipda: "220",
            quantity: "2",
            cost: "587,664",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CRS(긴급비용할증료)",
            shipda: "40",
            quantity: "2",
            cost: "106,848",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "130,000",
            quantity: "2",
            cost: "260,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "WFG(부두사용료)",
            shipda: "4,420",
            quantity: "2",
            cost: "8,840",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
            shipda: "35,000",
            quantity: "2",
            cost: "70,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "D/O FEE(화물인도지시서)",
            shipda: "0",
            quantity: "1",
            cost: "0",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
        ],
        localCost: [],
        inlandCost: [],
        taxCost: [],
        otherCost: [],
        etc: [
          {
            category: "ROUTE(구간)",
            detail: "상하이항 -> 인천항",
          },
          {
            category: "LINER(선사)",
            detail: "태영상선(Taiyung Shipping)",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 06월 30일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 06월 07일 -> 2023년 06월 09일",
          },
          {
            category: "LEAD TIME(소요일)",
            detail: "3",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
        total: "2,335,392",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 2335392,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 2335392,
      inlandCost: 0,
      estimateLogId: 8137,
    },
  },
  {
    schedule: {
      id: 55846,
      carrier: "SINOKOR",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306070000",
      eta: "202306090000",
      transitTimeInDays: 3,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 59419,
      carrier: "KMTC",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306090000",
      eta: "202306100000",
      transitTimeInDays: 2,
      docCloseTime: "202306071600",
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 65918,
      carrier: "PANCON",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306110000",
      eta: "202306130000",
      transitTimeInDays: 3,
      docCloseTime: "202306081600",
      cargoCloseTime: "202306091600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 63657,
      carrier: "HEUNG-A",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306110000",
      eta: "202306130000",
      transitTimeInDays: 3,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 57488,
      carrier: "CK",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306110000",
      eta: "202306130000",
      transitTimeInDays: 3,
      docCloseTime: "202306081600",
      cargoCloseTime: "202306081600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 59418,
      carrier: "KMTC",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306110000",
      eta: "202306130000",
      transitTimeInDays: 3,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 59417,
      carrier: "KMTC",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRICN",
      podPortName: "NEW INCHEON",
      etd: "202306120000",
      eta: "202306130000",
      transitTimeInDays: 2,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 65921,
      carrier: "PANCON",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306140000",
      eta: "202306160000",
      transitTimeInDays: 3,
      docCloseTime: "202306081600",
      cargoCloseTime: "202306121600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 55847,
      carrier: "SINOKOR",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306140000",
      eta: "202306160000",
      transitTimeInDays: 3,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 65470,
      carrier: "TAIYOUNG",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306140000",
      eta: "202306160000",
      transitTimeInDays: 3,
      docCloseTime: "202306151600",
      cargoCloseTime: "202306161600",
      ts: false,
    },
    quote: {
      freightType: "FCL",
      isTempPrice: false,
      fareId: 1751,
      userId: 399,
      cost: 2335392,
      containInland: false,
      exchangeRate: 1335.6,
      isExpectVAT: true,
      localFee: [],
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 1202040,
          unitPrice: 450,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 587664,
          unitPrice: 220,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 106848,
          unitPrice: 40,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 260000,
          unitPrice: 130000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 8840,
          unitPrice: 4420,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 70000,
          unitPrice: 35000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      id: 72954,
      fileKey: null,
      createdAt: "2023-05-10T02:41:17.000Z",
      searchEstimateId: 72954,
      fare: {
        id: 1751,
        expiredAt: "2023-06-30",
        liner: "태영상선(Taiyung Shipping)",
        linerId: 167,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "FCL",
        currency: {
          usd: "1335.6",
          euro: "1469.59",
          yuan: "193.05",
        },
        request: {
          fob: "FOB조건(상하이항 -> 인천항)",
          item: "we",
          load: "20DRY x 2대",
        },
        oceanFreight: [
          {
            category: "OCEAN FREIGHT(해상운임)",
            shipda: "450",
            quantity: "2",
            cost: "1,202,040",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "BAF & CAF(유류할증료&통화할증료)",
            shipda: "220",
            quantity: "2",
            cost: "587,664",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CRS(긴급비용할증료)",
            shipda: "40",
            quantity: "2",
            cost: "106,848",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "130,000",
            quantity: "2",
            cost: "260,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "WFG(부두사용료)",
            shipda: "4,420",
            quantity: "2",
            cost: "8,840",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
            shipda: "35,000",
            quantity: "2",
            cost: "70,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "D/O FEE(화물인도지시서)",
            shipda: "0",
            quantity: "1",
            cost: "0",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
        ],
        localCost: [],
        inlandCost: [],
        taxCost: [],
        otherCost: [],
        etc: [
          {
            category: "ROUTE(구간)",
            detail: "상하이항 -> 인천항",
          },
          {
            category: "LINER(선사)",
            detail: "태영상선(Taiyung Shipping)",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 06월 30일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 06월 14일 -> 2023년 06월 16일",
          },
          {
            category: "LEAD TIME(소요일)",
            detail: "3",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
        total: "2,335,392",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 2335392,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 2335392,
      inlandCost: 0,
      estimateLogId: 8137,
    },
  },
  {
    schedule: {
      id: 59422,
      carrier: "KMTC",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306160000",
      eta: "202306170000",
      transitTimeInDays: 2,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 65920,
      carrier: "PANCON",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306180000",
      eta: "202306200000",
      transitTimeInDays: 3,
      docCloseTime: "202306151600",
      cargoCloseTime: "202306161600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 59421,
      carrier: "KMTC",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306180000",
      eta: "202306200000",
      transitTimeInDays: 3,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 57489,
      carrier: "CK",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306180000",
      eta: "202306200000",
      transitTimeInDays: 3,
      docCloseTime: "202306151600",
      cargoCloseTime: "202306151600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 63661,
      carrier: "HEUNG-A",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306180000",
      eta: "202306200000",
      transitTimeInDays: 3,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 59420,
      carrier: "KMTC",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRICN",
      podPortName: "NEW INCHEON",
      etd: "202306190000",
      eta: "202306200000",
      transitTimeInDays: 2,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 65923,
      carrier: "PANCON",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306210000",
      eta: "202306230000",
      transitTimeInDays: 3,
      docCloseTime: "202306151600",
      cargoCloseTime: "202306191600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 65472,
      carrier: "TAIYOUNG",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306210000",
      eta: "202306230000",
      transitTimeInDays: 3,
      docCloseTime: "202306221600",
      cargoCloseTime: "202306231600",
      ts: false,
    },
    quote: {
      freightType: "FCL",
      isTempPrice: false,
      fareId: 1751,
      userId: 399,
      cost: 2335392,
      containInland: false,
      exchangeRate: 1335.6,
      isExpectVAT: true,
      localFee: [],
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 1202040,
          unitPrice: 450,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 587664,
          unitPrice: 220,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 106848,
          unitPrice: 40,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 260000,
          unitPrice: 130000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 8840,
          unitPrice: 4420,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 70000,
          unitPrice: 35000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      id: 72954,
      fileKey: null,
      createdAt: "2023-05-10T02:41:17.000Z",
      searchEstimateId: 72954,
      fare: {
        id: 1751,
        expiredAt: "2023-06-30",
        liner: "태영상선(Taiyung Shipping)",
        linerId: 167,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "FCL",
        currency: {
          usd: "1335.6",
          euro: "1469.59",
          yuan: "193.05",
        },
        request: {
          fob: "FOB조건(상하이항 -> 인천항)",
          item: "we",
          load: "20DRY x 2대",
        },
        oceanFreight: [
          {
            category: "OCEAN FREIGHT(해상운임)",
            shipda: "450",
            quantity: "2",
            cost: "1,202,040",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "BAF & CAF(유류할증료&통화할증료)",
            shipda: "220",
            quantity: "2",
            cost: "587,664",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CRS(긴급비용할증료)",
            shipda: "40",
            quantity: "2",
            cost: "106,848",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "130,000",
            quantity: "2",
            cost: "260,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "WFG(부두사용료)",
            shipda: "4,420",
            quantity: "2",
            cost: "8,840",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
            shipda: "35,000",
            quantity: "2",
            cost: "70,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "D/O FEE(화물인도지시서)",
            shipda: "0",
            quantity: "1",
            cost: "0",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
        ],
        localCost: [],
        inlandCost: [],
        taxCost: [],
        otherCost: [],
        etc: [
          {
            category: "ROUTE(구간)",
            detail: "상하이항 -> 인천항",
          },
          {
            category: "LINER(선사)",
            detail: "태영상선(Taiyung Shipping)",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 06월 30일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 06월 21일 -> 2023년 06월 23일",
          },
          {
            category: "LEAD TIME(소요일)",
            detail: "3",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
        total: "2,335,392",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 2335392,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 2335392,
      inlandCost: 0,
      estimateLogId: 8137,
    },
  },
  {
    schedule: {
      id: 55848,
      carrier: "SINOKOR",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306210000",
      eta: "202306230000",
      transitTimeInDays: 3,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 59424,
      carrier: "KMTC",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306230000",
      eta: "202306240000",
      transitTimeInDays: 2,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 72519,
      carrier: "KMTC",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRICN",
      podPortName: "NEW INCHEON",
      etd: "202306250000",
      eta: "202306270000",
      transitTimeInDays: 3,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 57490,
      carrier: "CK",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306250000",
      eta: "202306270000",
      transitTimeInDays: 3,
      docCloseTime: "202306221600",
      cargoCloseTime: "202306221600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 65922,
      carrier: "PANCON",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306250000",
      eta: "202306270000",
      transitTimeInDays: 3,
      docCloseTime: "202306221600",
      cargoCloseTime: "202306231600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 59423,
      carrier: "KMTC",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306250000",
      eta: "202306270000",
      transitTimeInDays: 3,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 63663,
      carrier: "HEUNG-A",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306250000",
      eta: "202306270000",
      transitTimeInDays: 3,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 65925,
      carrier: "PANCON",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306280000",
      eta: "202306300000",
      transitTimeInDays: 3,
      docCloseTime: "202306221600",
      cargoCloseTime: "202306261600",
      ts: false,
    },
  },
  {
    schedule: {
      id: 73078,
      carrier: "SINOKOR",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306280000",
      eta: "202306300000",
      transitTimeInDays: 3,
      docCloseTime: null,
      cargoCloseTime: null,
      ts: false,
    },
  },
  {
    schedule: {
      id: 65474,
      carrier: "TAIYOUNG",
      pol: "CNSHG",
      polPortName: "SHANGHAI",
      pod: "KRINC",
      podPortName: "INCHEON",
      etd: "202306280000",
      eta: "202306300000",
      transitTimeInDays: 3,
      docCloseTime: "202306291600",
      cargoCloseTime: "202306301600",
      ts: false,
    },
    quote: {
      freightType: "FCL",
      isTempPrice: false,
      fareId: 1751,
      userId: 399,
      cost: 2335392,
      containInland: false,
      exchangeRate: 1335.6,
      isExpectVAT: true,
      localFee: [],
      freightFee: [
        {
          key: "oceanFreight20DRY",
          item: "OCEAN FREIGHT(해상운임)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 1202040,
          unitPrice: 450,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "bafCaf20DRY",
          item: "BAF & CAF(유류할증료&통화할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 587664,
          unitPrice: 220,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "totalAdditionalFee20DRY",
          item: "CRS(긴급비용할증료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "USD",
          itemPrice: 106848,
          unitPrice: 40,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
      ],
      domesticFee: [
        {
          key: "thc20DRY",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 260000,
          unitPrice: 130000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "wfg20DRY",
          item: "WFG(부두사용료)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 8840,
          unitPrice: 4420,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "ccc20DRY",
          item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
          note: "",
          amount: 2,
          atCost: false,
          currency: "KRW",
          itemPrice: 70000,
          unitPrice: 35000,
          isVAT: false,
          itemUnitMeasurement: "CNTR(20DRY)",
        },
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 50000,
          unitPrice: 50000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "doFee",
          item: "D/O FEE(화물인도지시서)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [],
      taxFee: [],
      otherFee: [],
      id: 72954,
      fileKey: null,
      createdAt: "2023-05-10T02:41:17.000Z",
      searchEstimateId: 72954,
      fare: {
        id: 1751,
        expiredAt: "2023-06-30",
        liner: "태영상선(Taiyung Shipping)",
        linerId: 167,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "FCL",
        currency: {
          usd: "1335.6",
          euro: "1469.59",
          yuan: "193.05",
        },
        request: {
          fob: "FOB조건(상하이항 -> 인천항)",
          item: "we",
          load: "20DRY x 2대",
        },
        oceanFreight: [
          {
            category: "OCEAN FREIGHT(해상운임)",
            shipda: "450",
            quantity: "2",
            cost: "1,202,040",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "BAF & CAF(유류할증료&통화할증료)",
            shipda: "220",
            quantity: "2",
            cost: "587,664",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CRS(긴급비용할증료)",
            shipda: "40",
            quantity: "2",
            cost: "106,848",
            unit: "CNTR(20DRY)",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "130,000",
            quantity: "2",
            cost: "260,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "WFG(부두사용료)",
            shipda: "4,420",
            quantity: "2",
            cost: "8,840",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
            shipda: "35,000",
            quantity: "2",
            cost: "70,000",
            unit: "CNTR(20DRY)",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "50,000",
            quantity: "1",
            cost: "50,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "D/O FEE(화물인도지시서)",
            shipda: "0",
            quantity: "1",
            cost: "0",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
        ],
        localCost: [],
        inlandCost: [],
        taxCost: [],
        otherCost: [],
        etc: [
          {
            category: "ROUTE(구간)",
            detail: "상하이항 -> 인천항",
          },
          {
            category: "LINER(선사)",
            detail: "태영상선(Taiyung Shipping)",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 06월 30일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 06월 28일 -> 2023년 06월 30일",
          },
          {
            category: "LEAD TIME(소요일)",
            detail: "3",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 도착지 Freetime은 별도 협의가 없을 경우, 선사별 기본 Tariff로 적용됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 국내 내륙운송료는 국가에서 지정한 '안전운임제'에 적용 받습니다.",
        total: "2,335,392",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 2335392,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 2335392,
      inlandCost: 0,
      estimateLogId: 8137,
    },
  },
] as GET_FCL_QUOTATION_RES_ITEM[];

const createSampleFCLQuotationItemForLogoTest = (
  carrier: VESSEL_CARRIER
): GET_FCL_QUOTATION_RES_ITEM => {
  return {
    quote: { cost: 50000 } as RealtimeQuotationFCL,
    schedule: {
      id: newId() as unknown as number,
      carrier,
      pol: "CNSHG",
      polPortName: "Shanghai Port",
      pod: "KRPUS",
      podPortName: "Korea Incheon Port",
      etd: "202304112300",
      eta: "202304140800",
      transitTimeInDays: 3,
      docCloseTime: "202304131200",
      cargoCloseTime: undefined,
      ts: false,
    },
  };
};

const FCL_QUOTATION_LIST_FOR_CARRIER_LOGO_TEST: GET_FCL_QUOTATION_RES = [
  {
    quote: { cost: 10000 } as RealtimeQuotationFCL,
    schedule: {
      id: newId() as unknown as number,
      carrier: "TAIYOUNG",
      pol: "CNSHG",
      polPortName: "Shanghai Port",
      pod: "KRPUS",
      podPortName: "Korea Incheon Port",
      etd: "202304112300",
      eta: "202304140800",
      transitTimeInDays: 3,
      docCloseTime: "202304131200",
      cargoCloseTime: undefined,
      ts: false,
    },
  },
  {
    // quote 만있는 최저가 간젹
    quote: { cost: 3000 } as RealtimeQuotationFCL,
  },
  {
    quote: { cost: 7000 } as RealtimeQuotationFCL,
    schedule: {
      id: newId() as unknown as number,
      carrier: "COSCO",
      pol: "CNSHG",
      polPortName: "Shanghai Port",
      pod: "KRPUS",
      podPortName: "Korea Incheon Port",
      etd: "202304102300",
      eta: "202304140800",
      transitTimeInDays: 3,
      docCloseTime: "202304151200",
      cargoCloseTime: undefined,
      ts: true,
    },
  },
  {
    quote: { cost: 12000 } as RealtimeQuotationFCL,
    schedule: {
      id: newId() as unknown as number,
      carrier: "HAPAG-LLOYD",
      pol: "CNSHG",
      polPortName: "Shanghai Port",
      pod: "KRPUS",
      podPortName: "Korea Incheon Port",
      etd: "202304192300",
      eta: "202304140800",
      transitTimeInDays: 3,
      docCloseTime: "202304151200",
      cargoCloseTime: undefined,
      ts: true,
    },
  },
  createSampleFCLQuotationItemForLogoTest("KMTC"),
  createSampleFCLQuotationItemForLogoTest("SINOKOR"),
  createSampleFCLQuotationItemForLogoTest("CK"),
  createSampleFCLQuotationItemForLogoTest("ANL"),
  createSampleFCLQuotationItemForLogoTest("APL"),
  createSampleFCLQuotationItemForLogoTest("CMACGM"),
  createSampleFCLQuotationItemForLogoTest("COSCO"),
  createSampleFCLQuotationItemForLogoTest("CNC"),
  createSampleFCLQuotationItemForLogoTest("DONGJIN"),
  createSampleFCLQuotationItemForLogoTest("DONGYOUNG"),
  createSampleFCLQuotationItemForLogoTest("DOOWOO"),
  createSampleFCLQuotationItemForLogoTest("ESL"),
  createSampleFCLQuotationItemForLogoTest("EAS"),
  createSampleFCLQuotationItemForLogoTest("EVERGREEN"),
  createSampleFCLQuotationItemForLogoTest("HS"),
  createSampleFCLQuotationItemForLogoTest("HEUNG-A"),
  createSampleFCLQuotationItemForLogoTest("HAPAG-LLOYD"),
  createSampleFCLQuotationItemForLogoTest("MAERSK"),
  createSampleFCLQuotationItemForLogoTest("MSC"),
  createSampleFCLQuotationItemForLogoTest("NAMSUNG"),
  createSampleFCLQuotationItemForLogoTest("ONE"),
  createSampleFCLQuotationItemForLogoTest("WEIDONG"),
  createSampleFCLQuotationItemForLogoTest("YANGMING"),
  createSampleFCLQuotationItemForLogoTest("ZIM"),
  createSampleFCLQuotationItemForLogoTest("OOCL"),
  createSampleFCLQuotationItemForLogoTest("PANOCEAN"),
  createSampleFCLQuotationItemForLogoTest("PANCON"),
  createSampleFCLQuotationItemForLogoTest("PIL"),
  createSampleFCLQuotationItemForLogoTest("SEALAND"),
  createSampleFCLQuotationItemForLogoTest("SITC"),
  createSampleFCLQuotationItemForLogoTest("TAIYOUNG"),
  createSampleFCLQuotationItemForLogoTest("TS"),
  createSampleFCLQuotationItemForLogoTest("WANHAI"),
  createSampleFCLQuotationItemForLogoTest("HMM"),
];

const LCL_QUOTATION_LIST: GET_LCL_QUOTATION_RES = [
  {
    serviceType: ServiceType.General,
    incoterms: "FOB",
    quote: { cost: 10000 } as RealtimeQuotationLCL,
    schedule: {
      id: newId() as unknown as number,
      carrier: "SHIPDA",
      pol: "CNSHG",
      polPortName: "Shanghai Port",
      polPortKrName: "상해항",
      pod: "KRPUS",
      podPortKrName: "인천항",
      podPortName: "Korea Incheon Port",
      etd: "202304112300",
      eta: "202304140800",
      transitTimeInDays: 3,
      docCloseTime: "202304131200",
      cargoCloseTime: undefined,
      ts: false,
    },
  },
  {
    // quote 만있는 최저가 간젹
    serviceType: ServiceType.General,
    incoterms: "FOB",
    quote: { cost: 3000 } as RealtimeQuotationLCL,
  },
  {
    serviceType: ServiceType.consolidation,
    incoterms: "EXW",
    quote: {
      cost: 172879,
      domesticAndOceanCost: 172879,
      originalPrice: 172879,
      isTempPrice: true,
    } as RealtimeQuotationLCL,
    schedule: {
      id: newId() as unknown as number,
      cargoCloseTime: "202307031600",
      carrier: "SHIPDA",
      docCloseTime: "202307031600",
      eta: "202307130000",
      etd: "202307060000",
      pod: "KRINC",
      podPortKrName: "인천항",
      podPortName: "INCHEON",
      pol: "CNWEI",
      polPortKrName: "웨이하이항",
      polPortName: "WEIHAI",
      transitTimeInDays: 7,
      ts: false,
      wareHouse: { id: 12, name: "상해" },
    },
  },
  {
    serviceType: ServiceType.General,
    incoterms: "FOB",
    quote: { cost: 12000 } as RealtimeQuotationLCL,
    schedule: {
      id: newId() as unknown as number,
      carrier: "SHIPDA",
      pol: "CNSHG",
      polPortName: "Shanghai Port",
      pod: "KRPUS",
      polPortKrName: "상해항",
      podPortKrName: "인천항",
      podPortName: "Korea Incheon Port",
      etd: "202304192300",
      eta: "202304140800",
      transitTimeInDays: 3,
      docCloseTime: "202304151200",
      cargoCloseTime: undefined,
      ts: true,
    },
  },
];

const GET_PARTNER_DETAIL_DUMMY: GET_PARTNER_DETAIL_RES = {
  id: 1,
  contractorNumber: "2",
  company: "다람쥐 회사",
  country: "한국",
  address: "서울시",
  detailAddress: "중구",
  jibunAddress: null,
  postalCode: null,
  managers: [
    {
      id: 2,
      name: "Esther Howard",
      email: "shin@sony.com",
      phone: "505-555-0125",
    },
    {
      id: 1,
      name: "Courtney Henry",
      email: "nevaeh.simmons@example.com",
      phone: "629-555-0129",
    },
  ],
};

const createSampleAIRQuotationItemForLogoTest = (
  airline: AIRLINE
): GET_AIR_QUOTATION_RES_ITEM => {
  return {
    quote: { cost: 50000, freightType: "AIR" } as RealtimeQuotationAIR,
    schedule: {
      id: newId() as unknown as number,
      carrier: airline,
      pol: "YIW",
      polPortName: "Yiwu Airport",
      polPortKrName: "이우 공항",
      pod: "ICN",
      podPortName: "INCHEON",
      podPortKrName: "인천 공항",
      etd: "202304112300",
      eta: "202304140800",
      transitTimeInDays: 3,
      docCloseTime: "202304131200",
      cargoCloseTime: undefined,
      ts: false,
    },
  };
};

const AIR_QUOTATION_LIST: GET_AIR_QUOTATION_RES = [
  {
    schedule: {
      id: 289386,
      carrier: "OZ",
      pol: "USLAX",
      polPortName: "LOS ANGELES",
      polPortKrName: "로스앤젤레스 공항",
      pod: "KRICN",
      podPortName: "INCHEON",
      podPortKrName: "인천 공항",
      etd: "202307150000",
      eta: "202307170000",
      transitTimeInDays: 2,
      docCloseTime: "203102231600",
      cargoCloseTime: "203102231600",
      ts: false,
    },
    quote: {
      freightType: "AIR",
      serviceType: "general",
      isTempPrice: false,
      fareId: 31,
      userId: 3194,
      cost: 581273,
      containInland: true,
      exchangeRate: 1316.5,
      isExpectVAT: true,
      freightFee: [
        {
          key: "airFreight",
          item: "AIR FREIGHT(항공운임)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "USD",
          itemPrice: 160745,
          unitPrice: 1.1,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
        {
          key: "fsc",
          item: "FSC(유류 할증료)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "USD",
          itemPrice: 153439,
          unitPrice: 1.05,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
        {
          key: "ssc",
          item: "SSC(보안 할증료)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "USD",
          itemPrice: 14614,
          unitPrice: 0.1,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
      ],
      domesticFee: [
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 40000,
          unitPrice: 40000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "thc",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "KRW",
          itemPrice: 16650,
          unitPrice: 150,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 40000,
          unitPrice: 40000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "customsClearanceFee",
          item: "CUSTOMS CLEARANCE FEE(통관대행수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 30000,
          unitPrice: 30000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "insuranceFee",
          item: "INSURANCE FEE(적하보험료)",
          note: "신청",
          amount: 1,
          atCost: true,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: false,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [
        {
          key: "tc",
          item: "TRUCKING CHARGE(내륙운송료)",
          note: "독차",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 60000,
          unitPrice: 60000,
          isVAT: true,
          itemUnitMeasurement: "TRUCK",
        },
      ],
      otherFee: [
        {
          key: "inspectionFee",
          item: "INSPECTION FEE(세관검사)",
          atCost: true,
          isVAT: false,
          note: "적발 시",
        },
      ],
      taxFee: [
        {
          key: "customsFee",
          item: "관세",
          atCost: true,
          isVAT: false,
          note: "",
        },
        {
          key: "VATFee",
          item: "부가가치세",
          atCost: true,
          isVAT: false,
          note: "",
        },
      ],
      localFee: [
        {
          key: "ftaCertificateAgencyFee",
          item: "FTA C/O(FTA원산지증명서발급)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "USD",
          itemPrice: 65825,
          unitPrice: 50,
          isVAT: false,
          itemUnitMeasurement: "B/L",
        },
      ],
      id: 74824,
      fileKey: null,
      createdAt: "2023-07-09T23:12:25.000Z",
      searchEstimateId: 74824,
      fare: {
        id: 31,
        expiredAt: "2023-12-31",
        liner: "",
        linerId: 2,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "AIR",
        currency: {
          euro: "1434.56",
          usd: "1316.5",
          yuan: "181.23",
        },
        request: {
          fob: "FCA조건(픽업지 -> 로스앤젤레스 공항 -> 인천 공항 -> 인천광역시 중구 자유무역로 232)",
          item: "1111",
          load: "111 C.W.",
        },
        airFreight: [
          {
            category: "AIR FREIGHT(항공운임)",
            shipda: "1.1",
            quantity: "111",
            cost: "160,745",
            unit: "C/W",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "FSC(유류 할증료)",
            shipda: "1.05",
            quantity: "111",
            cost: "153,439",
            unit: "C/W",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "SSC(보안 할증료)",
            shipda: "0.1",
            quantity: "111",
            cost: "14,614",
            unit: "C/W",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "40,000",
            quantity: "1",
            cost: "40,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "150",
            quantity: "111",
            cost: "16,650",
            unit: "C/W",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "40,000",
            quantity: "1",
            cost: "40,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "CUSTOMS CLEARANCE FEE(통관대행수수료)",
            shipda: "30,000",
            quantity: "1",
            cost: "30,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "INSURANCE FEE(적하보험료)",
            atCost: true,
            etc: "신청",
          },
        ],
        localCost: [
          {
            category: "FTA C/O(FTA원산지증명서발급)",
            shipda: "50",
            quantity: "1",
            cost: "65,825",
            unit: "B/L",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        inlandCost: [
          {
            category: "TRUCKING CHARGE(내륙운송료)",
            shipda: "60,000",
            quantity: "1",
            cost: "60,000",
            unit: "TRUCK",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "독차",
          },
        ],
        taxCost: [
          {
            category: "관세",
            atCost: true,
            etc: "",
          },
          {
            category: "부가가치세",
            atCost: true,
            etc: "",
          },
        ],
        otherCost: [
          {
            category: "INSPECTION FEE(세관검사)",
            atCost: true,
            etc: "적발 시",
          },
        ],
        etc: [
          {
            category: "ROUTE(구간)",
            detail:
              "픽업지 -> 로스앤젤레스 공항 -> 인천 공항 -> 인천광역시 중구 자유무역로 232",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 12월 31일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 07월 15일 -> 2023년 07월 17일",
          },
          {
            category: "TRANSIT TIME(소요일)",
            detail: "2일",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 창고보관료는 실비로 청구됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 최종정산은 실제 선적된 화물의 부피 및 중량 정보를 바탕으로 청구됩니다.",
        total: "581,273",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 581273,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 521273,
      localCost: 65825,
      inlandCost: 60000,
      estimateLogId: 9742,
    },
  } as unknown as GET_AIR_QUOTATION_RES_ITEM,
  {
    schedule: {
      id: 289387,
      carrier: "LJ",
      pol: "USLAX",
      polPortName: "LOS ANGELES",
      polPortKrName: "로스앤젤레스 공항",
      pod: "KRICN",
      podPortName: "INCHEON",
      podPortKrName: "인천 공항",
      etd: "202307170000",
      eta: "202307190000",
      transitTimeInDays: 2,
      docCloseTime: "203102231600",
      cargoCloseTime: "203102231600",
      ts: false,
    },
    quote: {
      freightType: "AIR",
      serviceType: "general",
      isTempPrice: false,
      fareId: 31,
      userId: 3194,
      cost: 581273,
      containInland: true,
      exchangeRate: 1316.5,
      isExpectVAT: true,
      freightFee: [
        {
          key: "airFreight",
          item: "AIR FREIGHT(항공운임)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "USD",
          itemPrice: 160745,
          unitPrice: 1.1,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
        {
          key: "fsc",
          item: "FSC(유류 할증료)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "USD",
          itemPrice: 153439,
          unitPrice: 1.05,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
        {
          key: "ssc",
          item: "SSC(보안 할증료)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "USD",
          itemPrice: 14614,
          unitPrice: 0.1,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
      ],
      domesticFee: [
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 40000,
          unitPrice: 40000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "thc",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "KRW",
          itemPrice: 16650,
          unitPrice: 150,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 40000,
          unitPrice: 40000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "customsClearanceFee",
          item: "CUSTOMS CLEARANCE FEE(통관대행수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 30000,
          unitPrice: 30000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "insuranceFee",
          item: "INSURANCE FEE(적하보험료)",
          note: "신청",
          amount: 1,
          atCost: true,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: false,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [
        {
          key: "tc",
          item: "TRUCKING CHARGE(내륙운송료)",
          note: "독차",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 60000,
          unitPrice: 60000,
          isVAT: true,
          itemUnitMeasurement: "TRUCK",
        },
      ],
      otherFee: [
        {
          key: "inspectionFee",
          item: "INSPECTION FEE(세관검사)",
          atCost: true,
          isVAT: false,
          note: "적발 시",
        },
      ],
      taxFee: [
        {
          key: "customsFee",
          item: "관세",
          atCost: true,
          isVAT: false,
          note: "",
        },
        {
          key: "VATFee",
          item: "부가가치세",
          atCost: true,
          isVAT: false,
          note: "",
        },
      ],
      localFee: [
        {
          key: "ftaCertificateAgencyFee",
          item: "FTA C/O(FTA원산지증명서발급)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "USD",
          itemPrice: 65825,
          unitPrice: 50,
          isVAT: false,
          itemUnitMeasurement: "B/L",
        },
      ],
      id: 74826,
      fileKey: null,
      createdAt: "2023-07-09T23:12:25.000Z",
      searchEstimateId: 74826,
      fare: {
        id: 31,
        expiredAt: "2023-12-31",
        liner: "",
        linerId: 2,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "AIR",
        currency: {
          euro: "1434.56",
          usd: "1316.5",
          yuan: "181.23",
        },
        request: {
          fob: "FCA조건(픽업지 -> 로스앤젤레스 공항 -> 인천 공항 -> 인천광역시 중구 자유무역로 232)",
          item: "1111",
          load: "111 C.W.",
        },
        airFreight: [
          {
            category: "AIR FREIGHT(항공운임)",
            shipda: "1.1",
            quantity: "111",
            cost: "160,745",
            unit: "C/W",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "FSC(유류 할증료)",
            shipda: "1.05",
            quantity: "111",
            cost: "153,439",
            unit: "C/W",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "SSC(보안 할증료)",
            shipda: "0.1",
            quantity: "111",
            cost: "14,614",
            unit: "C/W",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "40,000",
            quantity: "1",
            cost: "40,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "150",
            quantity: "111",
            cost: "16,650",
            unit: "C/W",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "40,000",
            quantity: "1",
            cost: "40,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "CUSTOMS CLEARANCE FEE(통관대행수수료)",
            shipda: "30,000",
            quantity: "1",
            cost: "30,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "INSURANCE FEE(적하보험료)",
            atCost: true,
            etc: "신청",
          },
        ],
        localCost: [
          {
            category: "FTA C/O(FTA원산지증명서발급)",
            shipda: "50",
            quantity: "1",
            cost: "65,825",
            unit: "B/L",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        inlandCost: [
          {
            category: "TRUCKING CHARGE(내륙운송료)",
            shipda: "60,000",
            quantity: "1",
            cost: "60,000",
            unit: "TRUCK",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "독차",
          },
        ],
        taxCost: [
          {
            category: "관세",
            atCost: true,
            etc: "",
          },
          {
            category: "부가가치세",
            atCost: true,
            etc: "",
          },
        ],
        otherCost: [
          {
            category: "INSPECTION FEE(세관검사)",
            atCost: true,
            etc: "적발 시",
          },
        ],
        etc: [
          {
            category: "ROUTE(구간)",
            detail:
              "픽업지 -> 로스앤젤레스 공항 -> 인천 공항 -> 인천광역시 중구 자유무역로 232",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 12월 31일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 07월 17일 -> 2023년 07월 19일",
          },
          {
            category: "TRANSIT TIME(소요일)",
            detail: "2일",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 창고보관료는 실비로 청구됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 최종정산은 실제 선적된 화물의 부피 및 중량 정보를 바탕으로 청구됩니다.",
        total: "581,273",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 581273,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 521273,
      localCost: 65825,
      inlandCost: 60000,
      estimateLogId: 9742,
    },
  } as unknown as GET_AIR_QUOTATION_RES_ITEM,
  {
    schedule: {
      id: 289388,
      carrier: "KJ",
      pol: "USLAX",
      polPortName: "LOS ANGELES",
      polPortKrName: "로스앤젤레스 공항",
      pod: "KRICN",
      podPortName: "INCHEON",
      podPortKrName: "인천 공항",
      etd: "202307190000",
      eta: "202307210000",
      transitTimeInDays: 2,
      docCloseTime: "203102231600",
      cargoCloseTime: "203102231600",
      ts: false,
    },
    quote: {
      freightType: "AIR",
      serviceType: "general",
      isTempPrice: false,
      fareId: 31,
      userId: 3194,
      cost: 181273,
      containInland: true,
      exchangeRate: 1316.5,
      isExpectVAT: true,
      freightFee: [
        {
          key: "airFreight",
          item: "AIR FREIGHT(항공운임)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "USD",
          itemPrice: 160745,
          unitPrice: 1.1,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
        {
          key: "fsc",
          item: "FSC(유류 할증료)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "USD",
          itemPrice: 153439,
          unitPrice: 1.05,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
        {
          key: "ssc",
          item: "SSC(보안 할증료)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "USD",
          itemPrice: 14614,
          unitPrice: 0.1,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
      ],
      domesticFee: [
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 40000,
          unitPrice: 40000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "thc",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "KRW",
          itemPrice: 16650,
          unitPrice: 150,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 40000,
          unitPrice: 40000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "customsClearanceFee",
          item: "CUSTOMS CLEARANCE FEE(통관대행수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 30000,
          unitPrice: 30000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "insuranceFee",
          item: "INSURANCE FEE(적하보험료)",
          note: "신청",
          amount: 1,
          atCost: true,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: false,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [
        {
          key: "tc",
          item: "TRUCKING CHARGE(내륙운송료)",
          note: "독차",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 60000,
          unitPrice: 60000,
          isVAT: true,
          itemUnitMeasurement: "TRUCK",
        },
      ],
      otherFee: [
        {
          key: "inspectionFee",
          item: "INSPECTION FEE(세관검사)",
          atCost: true,
          isVAT: false,
          note: "적발 시",
        },
      ],
      taxFee: [
        {
          key: "customsFee",
          item: "관세",
          atCost: true,
          isVAT: false,
          note: "",
        },
        {
          key: "VATFee",
          item: "부가가치세",
          atCost: true,
          isVAT: false,
          note: "",
        },
      ],
      localFee: [
        {
          key: "ftaCertificateAgencyFee",
          item: "FTA C/O(FTA원산지증명서발급)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "USD",
          itemPrice: 65825,
          unitPrice: 50,
          isVAT: false,
          itemUnitMeasurement: "B/L",
        },
      ],
      id: 74827,
      fileKey: null,
      createdAt: "2023-07-09T23:12:25.000Z",
      searchEstimateId: 74827,
      fare: {
        id: 31,
        expiredAt: "2023-12-31",
        liner: "",
        linerId: 2,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "AIR",
        currency: {
          euro: "1434.56",
          usd: "1316.5",
          yuan: "181.23",
        },
        request: {
          fob: "FCA조건(픽업지 -> 로스앤젤레스 공항 -> 인천 공항 -> 인천광역시 중구 자유무역로 232)",
          item: "1111",
          load: "111 C.W.",
        },
        airFreight: [
          {
            category: "AIR FREIGHT(항공운임)",
            shipda: "1.1",
            quantity: "111",
            cost: "160,745",
            unit: "C/W",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "FSC(유류 할증료)",
            shipda: "1.05",
            quantity: "111",
            cost: "153,439",
            unit: "C/W",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "SSC(보안 할증료)",
            shipda: "0.1",
            quantity: "111",
            cost: "14,614",
            unit: "C/W",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "40,000",
            quantity: "1",
            cost: "40,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "150",
            quantity: "111",
            cost: "16,650",
            unit: "C/W",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "40,000",
            quantity: "1",
            cost: "40,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "CUSTOMS CLEARANCE FEE(통관대행수수료)",
            shipda: "30,000",
            quantity: "1",
            cost: "30,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "INSURANCE FEE(적하보험료)",
            atCost: true,
            etc: "신청",
          },
        ],
        localCost: [
          {
            category: "FTA C/O(FTA원산지증명서발급)",
            shipda: "50",
            quantity: "1",
            cost: "65,825",
            unit: "B/L",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        inlandCost: [
          {
            category: "TRUCKING CHARGE(내륙운송료)",
            shipda: "60,000",
            quantity: "1",
            cost: "60,000",
            unit: "TRUCK",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "독차",
          },
        ],
        taxCost: [
          {
            category: "관세",
            atCost: true,
            etc: "",
          },
          {
            category: "부가가치세",
            atCost: true,
            etc: "",
          },
        ],
        otherCost: [
          {
            category: "INSPECTION FEE(세관검사)",
            atCost: true,
            etc: "적발 시",
          },
        ],
        etc: [
          {
            category: "ROUTE(구간)",
            detail:
              "픽업지 -> 로스앤젤레스 공항 -> 인천 공항 -> 인천광역시 중구 자유무역로 232",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 12월 31일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 07월 19일 -> 2023년 07월 21일",
          },
          {
            category: "TRANSIT TIME(소요일)",
            detail: "2일",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 창고보관료는 실비로 청구됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 최종정산은 실제 선적된 화물의 부피 및 중량 정보를 바탕으로 청구됩니다.",
        total: "581,273",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 581273,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 521273,
      localCost: 65825,
      inlandCost: 60000,
      estimateLogId: 9742,
    },
  } as unknown as GET_AIR_QUOTATION_RES_ITEM,
  {
    schedule: {
      id: 289391,
      carrier: "TK",
      pol: "USLAX",
      polPortName: "LOS ANGELES",
      polPortKrName: "로스앤젤레스 공항",
      pod: "KRICN",
      podPortName: "INCHEON",
      podPortKrName: "인천 공항",
      etd: "202307260000",
      eta: "202307280000",
      transitTimeInDays: 2,
      docCloseTime: "203102231600",
      cargoCloseTime: "203102231600",
      ts: false,
    },
    quote: {
      freightType: "AIR",
      serviceType: "general",
      isTempPrice: false,
      fareId: 31,
      userId: 3194,
      cost: 581273,
      containInland: true,
      exchangeRate: 1316.5,
      isExpectVAT: true,
      freightFee: [
        {
          key: "airFreight",
          item: "AIR FREIGHT(항공운임)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "USD",
          itemPrice: 160745,
          unitPrice: 1.1,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
        {
          key: "fsc",
          item: "FSC(유류 할증료)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "USD",
          itemPrice: 153439,
          unitPrice: 1.05,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
        {
          key: "ssc",
          item: "SSC(보안 할증료)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "USD",
          itemPrice: 14614,
          unitPrice: 0.1,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
      ],
      domesticFee: [
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 40000,
          unitPrice: 40000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "thc",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "KRW",
          itemPrice: 16650,
          unitPrice: 150,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 40000,
          unitPrice: 40000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "customsClearanceFee",
          item: "CUSTOMS CLEARANCE FEE(통관대행수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 30000,
          unitPrice: 30000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "insuranceFee",
          item: "INSURANCE FEE(적하보험료)",
          note: "신청",
          amount: 1,
          atCost: true,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: false,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [
        {
          key: "tc",
          item: "TRUCKING CHARGE(내륙운송료)",
          note: "독차",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 60000,
          unitPrice: 60000,
          isVAT: true,
          itemUnitMeasurement: "TRUCK",
        },
      ],
      otherFee: [
        {
          key: "inspectionFee",
          item: "INSPECTION FEE(세관검사)",
          atCost: true,
          isVAT: false,
          note: "적발 시",
        },
      ],
      taxFee: [
        {
          key: "customsFee",
          item: "관세",
          atCost: true,
          isVAT: false,
          note: "",
        },
        {
          key: "VATFee",
          item: "부가가치세",
          atCost: true,
          isVAT: false,
          note: "",
        },
      ],
      localFee: [
        {
          key: "ftaCertificateAgencyFee",
          item: "FTA C/O(FTA원산지증명서발급)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "USD",
          itemPrice: 65825,
          unitPrice: 50,
          isVAT: false,
          itemUnitMeasurement: "B/L",
        },
      ],
      id: 74828,
      fileKey: null,
      createdAt: "2023-07-09T23:12:25.000Z",
      searchEstimateId: 74828,
      fare: {
        id: 31,
        expiredAt: "2023-12-31",
        liner: "",
        linerId: 2,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "AIR",
        currency: {
          euro: "1434.56",
          usd: "1316.5",
          yuan: "181.23",
        },
        request: {
          fob: "FCA조건(픽업지 -> 로스앤젤레스 공항 -> 인천 공항 -> 인천광역시 중구 자유무역로 232)",
          item: "1111",
          load: "111 C.W.",
        },
        airFreight: [
          {
            category: "AIR FREIGHT(항공운임)",
            shipda: "1.1",
            quantity: "111",
            cost: "160,745",
            unit: "C/W",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "FSC(유류 할증료)",
            shipda: "1.05",
            quantity: "111",
            cost: "153,439",
            unit: "C/W",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "SSC(보안 할증료)",
            shipda: "0.1",
            quantity: "111",
            cost: "14,614",
            unit: "C/W",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "40,000",
            quantity: "1",
            cost: "40,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "150",
            quantity: "111",
            cost: "16,650",
            unit: "C/W",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "40,000",
            quantity: "1",
            cost: "40,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "CUSTOMS CLEARANCE FEE(통관대행수수료)",
            shipda: "30,000",
            quantity: "1",
            cost: "30,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "INSURANCE FEE(적하보험료)",
            atCost: true,
            etc: "신청",
          },
        ],
        localCost: [
          {
            category: "FTA C/O(FTA원산지증명서발급)",
            shipda: "50",
            quantity: "1",
            cost: "65,825",
            unit: "B/L",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        inlandCost: [
          {
            category: "TRUCKING CHARGE(내륙운송료)",
            shipda: "60,000",
            quantity: "1",
            cost: "60,000",
            unit: "TRUCK",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "독차",
          },
        ],
        taxCost: [
          {
            category: "관세",
            atCost: true,
            etc: "",
          },
          {
            category: "부가가치세",
            atCost: true,
            etc: "",
          },
        ],
        otherCost: [
          {
            category: "INSPECTION FEE(세관검사)",
            atCost: true,
            etc: "적발 시",
          },
        ],
        etc: [
          {
            category: "ROUTE(구간)",
            detail:
              "픽업지 -> 로스앤젤레스 공항 -> 인천 공항 -> 인천광역시 중구 자유무역로 232",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 12월 31일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 07월 26일 -> 2023년 07월 28일",
          },
          {
            category: "TRANSIT TIME(소요일)",
            detail: "2일",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 창고보관료는 실비로 청구됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 최종정산은 실제 선적된 화물의 부피 및 중량 정보를 바탕으로 청구됩니다.",
        total: "581,273",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 581273,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 521273,
      localCost: 65825,
      inlandCost: 60000,
      estimateLogId: 9742,
    },
  } as unknown as GET_AIR_QUOTATION_RES_ITEM,
  {
    schedule: {
      id: 289392,
      carrier: "OZ",
      pol: "USLAX",
      polPortName: "LOS ANGELES",
      polPortKrName: "로스앤젤레스 공항",
      pod: "KRICN",
      podPortName: "INCHEON",
      podPortKrName: "인천 공항",
      etd: "202307290000",
      eta: "202307310000",
      transitTimeInDays: 2,
      docCloseTime: "203102231600",
      cargoCloseTime: "203102231600",
      ts: true,
    },
    quote: {
      freightType: "AIR",
      serviceType: "general",
      isTempPrice: false,
      fareId: 31,
      userId: 3194,
      cost: 581273,
      containInland: true,
      exchangeRate: 1316.5,
      isExpectVAT: true,
      freightFee: [
        {
          key: "airFreight",
          item: "AIR FREIGHT(항공운임)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "USD",
          itemPrice: 160745,
          unitPrice: 1.1,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
        {
          key: "fsc",
          item: "FSC(유류 할증료)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "USD",
          itemPrice: 153439,
          unitPrice: 1.05,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
        {
          key: "ssc",
          item: "SSC(보안 할증료)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "USD",
          itemPrice: 14614,
          unitPrice: 0.1,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
      ],
      domesticFee: [
        {
          key: "hc",
          item: "HANDLING CHARGE(포워더 대행 수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 40000,
          unitPrice: 40000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "thc",
          item: "THC(터미널 화물 처리비)",
          note: "",
          amount: 111,
          atCost: false,
          currency: "KRW",
          itemPrice: 16650,
          unitPrice: 150,
          isVAT: false,
          itemUnitMeasurement: "C/W",
        },
        {
          key: "docFee",
          item: "DOC FEE(서류 발급비)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 40000,
          unitPrice: 40000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "customsClearanceFee",
          item: "CUSTOMS CLEARANCE FEE(통관대행수수료)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 30000,
          unitPrice: 30000,
          isVAT: true,
          itemUnitMeasurement: "B/L",
        },
        {
          key: "insuranceFee",
          item: "INSURANCE FEE(적하보험료)",
          note: "신청",
          amount: 1,
          atCost: true,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          isVAT: false,
          itemUnitMeasurement: "B/L",
        },
      ],
      inlandFee: [
        {
          key: "tc",
          item: "TRUCKING CHARGE(내륙운송료)",
          note: "독차",
          amount: 1,
          atCost: false,
          currency: "KRW",
          itemPrice: 60000,
          unitPrice: 60000,
          isVAT: true,
          itemUnitMeasurement: "TRUCK",
        },
      ],
      otherFee: [
        {
          key: "inspectionFee",
          item: "INSPECTION FEE(세관검사)",
          atCost: true,
          isVAT: false,
          note: "적발 시",
        },
      ],
      taxFee: [
        {
          key: "customsFee",
          item: "관세",
          atCost: true,
          isVAT: false,
          note: "",
        },
        {
          key: "VATFee",
          item: "부가가치세",
          atCost: true,
          isVAT: false,
          note: "",
        },
      ],
      localFee: [
        {
          key: "ftaCertificateAgencyFee",
          item: "FTA C/O(FTA원산지증명서발급)",
          note: "",
          amount: 1,
          atCost: false,
          currency: "USD",
          itemPrice: 65825,
          unitPrice: 50,
          isVAT: false,
          itemUnitMeasurement: "B/L",
        },
      ],
      id: 74829,
      fileKey: null,
      createdAt: "2023-07-09T23:12:25.000Z",
      searchEstimateId: 74829,
      fare: {
        id: 31,
        expiredAt: "2023-12-31",
        liner: "",
        linerId: 2,
      },
      quotationInfoForDownload: {
        serviceType: "general",
        freightType: "AIR",
        currency: {
          euro: "1434.56",
          usd: "1316.5",
          yuan: "181.23",
        },
        request: {
          fob: "FCA조건(픽업지 -> 로스앤젤레스 공항 -> 인천 공항 -> 인천광역시 중구 자유무역로 232)",
          item: "1111",
          load: "111 C.W.",
        },
        airFreight: [
          {
            category: "AIR FREIGHT(항공운임)",
            shipda: "1.1",
            quantity: "111",
            cost: "160,745",
            unit: "C/W",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "FSC(유류 할증료)",
            shipda: "1.05",
            quantity: "111",
            cost: "153,439",
            unit: "C/W",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "SSC(보안 할증료)",
            shipda: "0.1",
            quantity: "111",
            cost: "14,614",
            unit: "C/W",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        domesticCost: [
          {
            category: "HANDLING CHARGE(포워더 대행 수수료)",
            shipda: "40,000",
            quantity: "1",
            cost: "40,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "THC(터미널 화물 처리비)",
            shipda: "150",
            quantity: "111",
            cost: "16,650",
            unit: "C/W",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
          {
            category: "DOC FEE(서류 발급비)",
            shipda: "40,000",
            quantity: "1",
            cost: "40,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "CUSTOMS CLEARANCE FEE(통관대행수수료)",
            shipda: "30,000",
            quantity: "1",
            cost: "30,000",
            unit: "B/L",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "",
          },
          {
            category: "INSURANCE FEE(적하보험료)",
            atCost: true,
            etc: "신청",
          },
        ],
        localCost: [
          {
            category: "FTA C/O(FTA원산지증명서발급)",
            shipda: "50",
            quantity: "1",
            cost: "65,825",
            unit: "B/L",
            shipdaC: "USD",
            costC: "KRW",
            isVAT: false,
            note: "",
          },
        ],
        inlandCost: [
          {
            category: "TRUCKING CHARGE(내륙운송료)",
            shipda: "60,000",
            quantity: "1",
            cost: "60,000",
            unit: "TRUCK",
            shipdaC: "KRW",
            costC: "KRW",
            isVAT: true,
            note: "독차",
          },
        ],
        taxCost: [
          {
            category: "관세",
            atCost: true,
            etc: "",
          },
          {
            category: "부가가치세",
            atCost: true,
            etc: "",
          },
        ],
        otherCost: [
          {
            category: "INSPECTION FEE(세관검사)",
            atCost: true,
            etc: "적발 시",
          },
        ],
        etc: [
          {
            category: "ROUTE(구간)",
            detail:
              "픽업지 -> 로스앤젤레스 공항 -> 인천 공항 -> 인천광역시 중구 자유무역로 232",
          },
          {
            category: "VALIDITY(운임 유효기간)",
            detail: "2023년 12월 31일",
          },
          {
            category: "ETD/ETA(예상출발일/예상도착일)",
            detail: "2023년 07월 29일 -> 2023년 07월 31일",
          },
          {
            category: "TRANSIT TIME(소요일)",
            detail: "2일",
          },
          {
            category: "T/S(환적여부)",
            detail: "아니오",
          },
        ],
        comment:
          "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 창고보관료는 실비로 청구됩니다. <br> - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> - 최종정산은 실제 선적된 화물의 부피 및 중량 정보를 바탕으로 청구됩니다.",
        total: "581,273",
        locale: "KR",
        adminContact: {
          email: "hjbang@ship-da.com",
          phone: "02-6956-7213",
          name: "방현정",
        },
      },
      originalPrice: 581273,
      firstDiscountPrice: 0,
      domesticAndOceanCost: 521273,
      localCost: 65825,
      inlandCost: 60000,
      estimateLogId: 9742,
    },
  } as unknown as GET_AIR_QUOTATION_RES_ITEM,
];

const AIR_QUOTATION_LIST_FOR_AIRLINE_LOGO_TEST: GET_AIR_QUOTATION_RES = [
  createSampleAIRQuotationItemForLogoTest("KE"),
  createSampleAIRQuotationItemForLogoTest("OZ"),
  createSampleAIRQuotationItemForLogoTest("7C"),
  createSampleAIRQuotationItemForLogoTest("LJ"),
  createSampleAIRQuotationItemForLogoTest("KJ"),
  createSampleAIRQuotationItemForLogoTest("JL"),
  createSampleAIRQuotationItemForLogoTest("CV"),
  createSampleAIRQuotationItemForLogoTest("C8"),
  createSampleAIRQuotationItemForLogoTest("NH"),
  createSampleAIRQuotationItemForLogoTest("YP"),
  createSampleAIRQuotationItemForLogoTest("TK"),
  createSampleAIRQuotationItemForLogoTest("MH"),
  createSampleAIRQuotationItemForLogoTest("ZH"),
  createSampleAIRQuotationItemForLogoTest("PO"),
  createSampleAIRQuotationItemForLogoTest("CX"),
  createSampleAIRQuotationItemForLogoTest("TG"),
  createSampleAIRQuotationItemForLogoTest("SQ"),
  createSampleAIRQuotationItemForLogoTest("AY"),
  createSampleAIRQuotationItemForLogoTest("CK"),
];

const MYPAGE_BID_LIST: {
  total: number;
  list: GET_USER_BID_LIST_RES_LIST_ITEM[];
} = {
  total: 1,
  list: [
    {
      status: "inProgress",
      projectStatus: "contactingPartner",
      cancelReason: undefined,
      updatedAt: new Date(),
      createdAt: new Date(),
      cta: {
        attachments: false,
        customs: false,
        invoices: false,
      },
      id: 102312,
      mBL: undefined,
      hBL: undefined,
      poNo: "PO102312",
      itemName: ["물품1", "물품2"],
      incoterms: "FOB",
      transportType: "ocean",
      freightType: "FCL",
      serviceType: "general",
      supply: 123,
      startPort: {
        code: "CNSGH",
        codeMT: null,
        country: "CN",
        id: 43,
        isActiveOceanTicketPort: true,
        isOceanTicketOriginPort: true,
        lat: 31.339001,
        lng: 121.660112,
        name: "상해항",
        nameC: "상하이항",
        nameEN: "Shanghai port",
        order: 1,
        region: "Asia",
        type: "sea",
        countryName: "중국",
      },
      startCountry: "CN",
      startAddress: undefined,
      startType: "sea",
      endPort: {
        code: "KRPUS",
        codeMT: "KRPUS",
        country: "KR",
        id: 1,
        isActiveOceanTicketPort: true,
        isOceanTicketOriginPort: true,
        lat: 35.108155,
        lng: 129.053807,
        name: "부산항",
        nameC: "부산구항",
        nameEN: "Busan port",
        order: 2,
        region: "Asia",
        type: "sea",
        countryName: "한국",
      },
      endCountry: "KR",
      endType: "sea",
      endAddress: undefined,
      endAddressDetail: undefined,
      wareHouse: undefined,
      supplierName: "납품처",
      supplierManagerName: "납품담당자",
      supplierManagerPhone: "01099549422",
      ETA: undefined,
      ETD: undefined,
      ATA: undefined,
      ATD: undefined,
      userId: 123,
      userName: "아무개",
      price: 123123,
      isAllSended: false,
      additional: {
        shipper: null,
        blType: "surrender",
        fds: null,
        por: null,
        carrier: "Shipda",
        vesselName: "PEGASUS GLORY",
        vesselIMO: null,
        voyager: "2409E",
        items: [
          {
            itemName: "ㄷㄱㄷㄱ",
            packingType: "pallets",
            quantity: 12,
            cbm: 0.03,
            idx: 0,
            containerType: "NONE",
          },
          {
            itemName: "ㄷㄱ",
            packingType: "boxes",
            quantity: 1,
            cbm: 6,
            idx: 1,
            containerType: "NONE",
          },
        ],
        containers: [],
        totalCBM: 6.03,
        gwt: 2021,
        lfd: null,
      },
    },
  ],
};

export {
  FCL_QUOTATION_LIST_FOR_SORTING_TEST,
  FCL_QUOTATION_LIST_FOR_CARRIER_LOGO_TEST,
  LCL_QUOTATION_LIST,
  GET_PARTNER_DETAIL_DUMMY,
  AIR_QUOTATION_LIST,
  AIR_QUOTATION_LIST_FOR_AIRLINE_LOGO_TEST,
  MYPAGE_BID_LIST,
};
