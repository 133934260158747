/**
 * Haversine 공식을 사용하여 두 좌표 사이의 거리를 계산하는 함수
 */
function haversineDistance({
  pinLat,
  pinLng,
  polylineLat,
  polylineLng,
}: {
  pinLat: number;
  pinLng: number;
  polylineLat: number;
  polylineLng: number;
}): number {
  // 지구 반지름 (킬로미터 단위)
  const earthRadiusKm = 6371;

  const deltaLat = ((polylineLat - pinLat) * Math.PI) / 180;
  const deltaLng = ((polylineLng - pinLng) * Math.PI) / 180;

  const haversineFormula =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos((pinLat * Math.PI) / 180) *
      Math.cos((polylineLat * Math.PI) / 180) *
      Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2);

  const centralAngle =
    2 *
    Math.atan2(Math.sqrt(haversineFormula), Math.sqrt(1 - haversineFormula));

  return earthRadiusKm * centralAngle;
}

/**
 * 특정 좌표와 routes 배열을 받아서 가장 근사치인 인덱스를 반환하는 함수
 */
export function findNearestIndex({
  pinLat,
  pinLng,
  routes,
}: {
  pinLat: number;
  pinLng: number;
  routes: { lat: number; lng: number }[];
}) {
  let nearestIndex = -1;
  let minDistance = Infinity;

  for (let i = 0; i < routes.length; i++) {
    const distance = haversineDistance({
      pinLat,
      pinLng,
      polylineLat: routes[i].lat,
      polylineLng: routes[i].lng,
    });

    if (distance < minDistance) {
      minDistance = distance;
      nearestIndex = i;
    }
  }

  return nearestIndex;
}
