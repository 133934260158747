import axios from "axios";
import jwt_decode from "jwt-decode";

import { MINUTE_AS_MILLISECONDS } from "./date";

interface AuthToken {
  exp: number;
}

/**
 * 인증 토큰이 유효한지 확인하고 필요시 refresh
 */
export function validateOrRefreshAuthToken({
  loginPagePath,
  tokenRefreshAPIUrl,
}: {
  loginPagePath: string;
  tokenRefreshAPIUrl: string;
}) {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  if (!accessToken || !refreshToken) return;

  const accessTokenDecoded = jwt_decode<AuthToken>(accessToken);

  const refreshTokenDecoded = jwt_decode<AuthToken>(refreshToken);

  const expiredTimeOfAccessToken = new Date(accessTokenDecoded.exp * 1000);
  const expiredTimeOfRefreshToken = new Date(refreshTokenDecoded.exp * 1000);

  // 리프레시 토큰의 유효기간이 10분미만으로 남았으면 토큰을 삭제함
  if (
    Date.now() >
    expiredTimeOfRefreshToken.getTime() - MINUTE_AS_MILLISECONDS * 10
  ) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.href = loginPagePath;
    return;
  }

  // 엑세스 토큰의 유효기간이 5분미만으로 남았을때는 토큰을 갱신함
  if (
    Date.now() >
    expiredTimeOfAccessToken.getTime() - MINUTE_AS_MILLISECONDS * 5
  ) {
    axios
      .post<{ accessToken: string }>(tokenRefreshAPIUrl, {
        refreshToken,
        accessToken,
      })
      .then((v) => {
        if (v.status === 401) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          window.location.href = loginPagePath;
        } else {
          localStorage.setItem("accessToken", v.data.accessToken);
        }
      });
  }
}

export const handleLoginSubmitByEnter = ({
  e,
  onLoginSubmit,
}: {
  e: React.KeyboardEvent<HTMLFormElement>;
  onLoginSubmit: () => void;
}) => {
  if (e.key === "Enter") onLoginSubmit();
};
