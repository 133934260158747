import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "../../services/query";

import {
  CHECK_IS_OCEANTICKET_REQ,
  CHECK_IS_OCEANTICKET_RES,
  CREATE_BID_REQ,
  CREATE_BID_REQ_PATH_PARAMS,
  CREATE_BID_RES,
  CREATE_EXPORT_BID_REQ,
  CREATE_EXPORT_BID_REQ_PATH_PARAMS,
  CREATE_EXPORT_BID_RES,
  GET_AIR_ESTIMATE_REQ,
  GET_AIR_ESTIMATE_RES,
  GET_FCL_ESTIMATE_REQ,
  GET_FCL_ESTIMATE_RES,
  GET_LCL_ESTIMATE_REQ,
  GET_LCL_ESTIMATE_RES,
  GET_OCEANTICKET_ESTIMATE_REQ,
  GET_OCEANTICKET_ESTIMATE_RES,
  GET_PURCHASE_ORDER_PRODUCTS_REQ,
  GET_PURCHASE_ORDER_PRODUCTS_RES,
} from "../../api-interfaces/shipda-api/adminBidCreate";

export const ADMIN_BID_CREATE_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_BID_CREATE_QUERY" }] as const,

  checkIsOceanticket: (params: CHECK_IS_OCEANTICKET_REQ) =>
    [
      {
        ...ADMIN_BID_CREATE_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "checkIsOceanticket",
      },
    ] as const,

  getFclEstimate: (params: GET_FCL_ESTIMATE_REQ | undefined) =>
    [
      {
        ...ADMIN_BID_CREATE_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getFclEstimate",
      },
    ] as const,

  getLclEstimate: (params: GET_LCL_ESTIMATE_REQ | undefined) =>
    [
      {
        ...ADMIN_BID_CREATE_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getLclEstimate",
      },
    ] as const,

  getAirEstimate: (params: GET_AIR_ESTIMATE_REQ | undefined) =>
    [
      {
        ...ADMIN_BID_CREATE_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAirEstimate",
      },
    ] as const,

  getOceanticketEstimate: (params: GET_OCEANTICKET_ESTIMATE_REQ | undefined) =>
    [
      {
        ...ADMIN_BID_CREATE_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getOceanticketEstimate",
      },
    ] as const,

  getPurchaseOrderProducts: (params: GET_PURCHASE_ORDER_PRODUCTS_REQ) =>
    [
      {
        ...ADMIN_BID_CREATE_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getPurchaseOrderProduct",
      },
    ] as const,
};

function useCheckIsOceanticket({
  enabled,
  ...params
}: CHECK_IS_OCEANTICKET_REQ & { enabled: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_CREATE_QUERY_KEY_GEN.checkIsOceanticket>,
    CHECK_IS_OCEANTICKET_RES
  >({
    queryKey: ADMIN_BID_CREATE_QUERY_KEY_GEN.checkIsOceanticket(params),
    requestOptions: {
      method: "get",
      path: `/searchEstimate/oceanTicket/check`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },

    enabled,
  });

  return { ...queryResult };
}

function useCreateBid() {
  const mutation = useAppMutation<
    CREATE_BID_REQ,
    CREATE_BID_RES,
    CREATE_BID_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "post",
      path: ({ userId, teamId }) =>
        `/bid/register?userId=${userId}&teamId=${teamId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetFclEstimate({
  enabled,
  params,
}: {
  params: GET_FCL_ESTIMATE_REQ | undefined;
  enabled: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_CREATE_QUERY_KEY_GEN.getFclEstimate>,
    GET_FCL_ESTIMATE_RES
  >({
    queryKey: ADMIN_BID_CREATE_QUERY_KEY_GEN.getFclEstimate(params),
    requestOptions: {
      method: "get",
      path: `/searchEstimate/fcl`,
      apiType: "ShipdaAdminDefaultNew",
      params: enabled ? params : undefined,
    },
    enabled,
  });

  return { ...queryResult };
}

function useGetLclEstimate({
  enabled,
  params,
}: {
  enabled: boolean;
  params: GET_LCL_ESTIMATE_REQ | undefined;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_CREATE_QUERY_KEY_GEN.getLclEstimate>,
    GET_LCL_ESTIMATE_RES
  >({
    queryKey: ADMIN_BID_CREATE_QUERY_KEY_GEN.getLclEstimate(params),
    requestOptions: {
      method: "get",
      path: `/searchEstimate/lcl`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    enabled,
  });

  return { ...queryResult };
}

function useGetAirEstimate({
  enabled,
  params,
}: {
  enabled: boolean;
  params: GET_AIR_ESTIMATE_REQ | undefined;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_CREATE_QUERY_KEY_GEN.getAirEstimate>,
    GET_AIR_ESTIMATE_RES
  >({
    queryKey: ADMIN_BID_CREATE_QUERY_KEY_GEN.getAirEstimate(params),
    requestOptions: {
      method: "get",
      path: `/searchEstimate/air`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    enabled,
  });

  return { ...queryResult };
}

function useGetOceanticketEstimate({
  enabled,
  params,
}: {
  enabled: boolean;
  params: GET_OCEANTICKET_ESTIMATE_REQ | undefined;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_CREATE_QUERY_KEY_GEN.getOceanticketEstimate>,
    GET_OCEANTICKET_ESTIMATE_RES
  >({
    queryKey: ADMIN_BID_CREATE_QUERY_KEY_GEN.getOceanticketEstimate(params),
    requestOptions: {
      method: "get",
      path: `/searchEstimate/lcl/integrate`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    enabled,
  });

  return { ...queryResult };
}

function useGetPurchaseOrderProducts({
  enabled,
  POId,
  ...params
}: GET_PURCHASE_ORDER_PRODUCTS_REQ & { POId?: number; enabled: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_BID_CREATE_QUERY_KEY_GEN.getPurchaseOrderProducts>,
    GET_PURCHASE_ORDER_PRODUCTS_RES
  >({
    queryKey: ADMIN_BID_CREATE_QUERY_KEY_GEN.getPurchaseOrderProducts(params),
    requestOptions: {
      method: "get",
      path: `/purchaseOrder/products/${POId}`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    enabled,
  });

  return { ...queryResult };
}

function useCreateExportBid() {
  const mutation = useAppMutation<
    CREATE_EXPORT_BID_REQ,
    CREATE_EXPORT_BID_RES,
    CREATE_EXPORT_BID_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "post",
      path: ({ userId, teamId }) =>
        `/export/register?userId=${userId}&teamId=${teamId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

const ADMIN_BID_CREATE_QUERY = {
  useCheckIsOceanticket,
  useCreateBid,
  useGetFclEstimate,
  useGetLclEstimate,
  useGetAirEstimate,
  useGetOceanticketEstimate,
  useGetPurchaseOrderProducts,
  useCreateExportBid,
};

export default ADMIN_BID_CREATE_QUERY;
