import { TeamRole } from "../../types/common/team";
import { TeamStatus } from "../../types/forwarding/adminTeam";

function changeTeamStatusToKr(status: TeamStatus | undefined) {
  switch (status) {
    case "delete":
      return "삭제";
    case "pause":
      return "중지";
    case "normal":
      return "정상";
    default:
      return "-";
  }
}

function changeTeamRoleToKr(status: TeamRole) {
  switch (status) {
    case "master":
      return "팀 마스터";
    case "manager":
      return "팀 관리자";
    case "staff":
      return "팀원";
  }
}

export { changeTeamStatusToKr, changeTeamRoleToKr };
