import { FreightType, Port } from "../../types/common/common";
import {
  BidCreateFormData,
  BidCreateStorageData,
  ShipmentCreateContainersInfo,
  ShipmentCreateProductsInfo,
} from "../../types/forwarding/adminBidCreate";

function getPortId(portList: Port[] | undefined, portName: string | undefined) {
  return (
    portList?.find((v) => {
      return (
        `${v.nameEN}(${v.name ?? ""}${v.code ? "/" : ""}${v.code ?? ""})` ===
        portName
      );
    })?.id || 0
  );
}

function getCountryOfPort(
  portList: Port[] | undefined,
  portName: string | undefined
) {
  return (
    portList?.find((v) => {
      return (
        `${v.nameEN}(${v.name ?? ""}${v.code ? "/" : ""}${v.code ?? ""})` ===
        portName
      );
    })?.country || ""
  );
}

function findPortItem(
  portList: Port[] | undefined,
  portName: string | undefined
) {
  return portList?.find((v) => {
    return (
      `${v.nameEN}(${v.name ?? ""}${v.code ? "/" : ""}${v.code ?? ""})` ===
      portName
    );
  });
}

/**
 * 의뢰 생성에서 페이지 이동 시 수정된 입력이 반영된 새로운 데이터를 리턴한다.
 */
function getNewShipmentCreateInfo({
  sessionStorageShipmentCreateInfo,
  formIndex,
  formData,
  disabled,
}: {
  sessionStorageShipmentCreateInfo: BidCreateStorageData;
  formIndex: number;
  formData: BidCreateFormData;
  /** 의뢰 생성이 복수인 경우 생성이 완료된 탭에 disabled처리를 위한 값 */
  disabled: boolean;
}) {
  const shallowCopiedShipmentCreateInfo = {
    ...sessionStorageShipmentCreateInfo,
  };

  const bidCreateData =
    shallowCopiedShipmentCreateInfo.bidCreateData[formIndex];

  shallowCopiedShipmentCreateInfo.bidCreateData[formIndex] = {
    ...bidCreateData,
    bidCreateFormData: formData,
    disabled,
  };

  return shallowCopiedShipmentCreateInfo;
}

/**
 * 의뢰 생성 요청 후 성공 핸들러
 */
const handleShipmentCreateSuccess = ({
  hasPO,
  sessionStorageShipmentCreateInfo,
  formIndex,
  formData,
  handleMoveToOrderPage,
  handleMoveToShipmentCreatePage,
  handleMoveToShipmentDetailPage,
  handleSessionStorageShipmentCreateInfoChange,
}: {
  hasPO: boolean | undefined;
  sessionStorageShipmentCreateInfo: BidCreateStorageData;
  formIndex: number;
  formData: BidCreateFormData;
  handleMoveToOrderPage: () => void;
  handleMoveToShipmentCreatePage?: () => void;
  handleMoveToShipmentDetailPage: () => void;
  handleSessionStorageShipmentCreateInfoChange: (
    value: BidCreateStorageData
  ) => void;
}) => {
  if (hasPO) {
    const newBidCreateInfo = getNewShipmentCreateInfo({
      sessionStorageShipmentCreateInfo: sessionStorageShipmentCreateInfo,
      formIndex,
      formData,
      disabled: true,
    });

    const isAllDisabled = newBidCreateInfo.bidCreateData.every((v) => {
      return v.disabled;
    });

    /** 모든 의뢰를 생성하는 경우 isAllDisabled는 true 발주 페이지로 이동함 */
    if (isAllDisabled) {
      handleMoveToOrderPage();
      return;
    }

    handleSessionStorageShipmentCreateInfoChange({
      ...newBidCreateInfo,
      /** 다음 의뢰 생성 탭으로 넘어가기 위해 + 1를 추가 */
      tabsDefaultValue: formIndex + 1,
    });

    /** 의뢰 생성이 복수인 경우 견적서 페이지에서 다시 의뢰 생성 페이지로 가야함 */
    handleMoveToShipmentCreatePage && handleMoveToShipmentCreatePage();

    return;
  }

  handleMoveToShipmentDetailPage();
};

const getItemNameList = ({
  freightType,
  containersInfo,
  productsInfo,
}: {
  freightType: FreightType;
  containersInfo: ShipmentCreateContainersInfo[] | undefined;
  productsInfo: ShipmentCreateProductsInfo[] | undefined;
}) => {
  if (freightType === "FCL") {
    return containersInfo?.map((v) => {
      return v.name;
    });
  }

  return productsInfo?.map((v) => {
    return v.name;
  });
};

/**
 * productsInfo에서 isDouble(2단 적재)여부가 true인지 확인한다.
 */
const checkIsDoubleFromProductsInfo = ({
  productsInfo,
}: {
  productsInfo: ShipmentCreateProductsInfo[] | undefined;
}) => {
  if (productsInfo) {
    return productsInfo.some((v) => {
      return v.isDouble;
    });
  }

  return false;
};

/**
 * productsInfo의 총 quantity값을 리턴한다.
 */
const getProductInfoTotalQuantity = ({
  productsInfo,
}: {
  productsInfo: ShipmentCreateProductsInfo[] | undefined;
}) => {
  if (productsInfo) {
    return productsInfo.reduce((acc, cur) => {
      acc += cur.quantity;

      return acc;
    }, 0);
  }

  return 0;
};

export {
  getPortId,
  getCountryOfPort,
  findPortItem,
  getNewShipmentCreateInfo,
  handleShipmentCreateSuccess,
  checkIsDoubleFromProductsInfo,
  getProductInfoTotalQuantity,
  getItemNameList,
};
