import {
  CustomsManager,
  PartnerListItem,
  PartnerManagerItem,
} from "../../types/forwarding/partner";

export const changeFetchCountryParam = (value: string) => {
  if (value === "전체") {
    return undefined;
  } else if (value === "전 세계") {
    return "ALL";
  } else {
    return value;
  }
};

export const GLOBELINK_SHANGHAI_FCL_PARTNER_ID = 952;

export const FARECON_FOREIGN_PARTNER_ID = 967;

export const GLOBELINKSHA_PARTNER_ID = 607;

export const GLOBELINK_SHANGHAI_EXPRESS_PARTNER_ID = 136;

export const FARECON_DOMESTIC_PARTNER_ID = 120;

export const GLOBELINK_KOREA_PARTNER_ID = 612;

export const getCustomsPartnerInfo = (
  customsPartner: PartnerListItem,
  customsManager: CustomsManager
) => {
  if (!customsPartner) {
    return "";
  }
  return `${customsPartner.name} (${customsManager?.name || "이름 없음"}, ${
    customsManager?.email || "이메일 없음"
  })`;
};

export const findMainManager = (managerList: PartnerManagerItem[]) => {
  return managerList.find((managerItem) => {
    return managerItem.isMain;
  });
};

export const getMainManagerInfo = (
  type: "name" | "position" | "phone" | "email",
  managerList: PartnerManagerItem[]
) => {
  const mainManagerItem = findMainManager(managerList);

  if (!mainManagerItem) {
    return "";
  }
  return mainManagerItem[type];
};
