import { atom } from "recoil";

const PARTNER_ID = atom<number>({
  // key에 file structure를 반영하여 unique가 보장되도록 한다
  key: "forwarding/partner/atoms/PARTNER_ID",
  default: 0,
});

export default {
  PARTNER_ID,
};
