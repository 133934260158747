import { Trans } from "react-i18next";

import { ResponseFailureInfo } from "@sellernote/_shared/src/types/common/common";

export function makeUserLoginFailedMessage({
  failureInfo,
}: {
  failureInfo?: ResponseFailureInfo;
}) {
  if (!failureInfo) return "";

  const { code, error } = failureInfo;
  switch (code) {
    case 400: {
      if (error === "Can not login with forwarder id") {
        return <Trans i18nKey="auth-new:API_로그인_로그인_물류사계정_에러" />;
      } else {
        return <Trans i18nKey="auth-new:API_로그인_로그인_입력오류_에러" />;
      }
    }

    case 404: {
      return (
        <Trans i18nKey="auth-new:API_로그인_로그인_가입되지않은_아이디_에러" />
      );
    }

    default: {
      return <Trans i18nKey="auth-new:API_로그인_로그인_서버_에러" />;
    }
  }
}
